const referenceTypes = {
  local: "local",
  remote: "remote",
};

export const usersTypes = {
  1: "super_admin",
  2: "regional_admin",
  3: "specialist",
  4: "doctor",
};

const usersStatus = {
  active: "active",
  not_active: "not_active",
};

const yesNo = {
  ДА: "ДА",
  НЕ: "НЕ",
};

export const subjectTypes = {
  first_check_up: "Прва проценка",
  control_check_up: "Контролна проценка",
  appeal: "Жалба",
  archived: "Стар предмет",
};

export const subjectStatuses = {
  in_progress: "Во изработка",
  approved: "Одобрен",
  issued: "Предаден",
  appealed: "Обжален",
  final: "Финален",
  re_evaluated: "Реевалуиран",
  canceled: "Откажан",
};

const codeSetupCategories = {
  child_support_measures: "child_support_measures",
  social_protection_measures: "social_protection_measures",
  health_care_measures: "health_care_measures",
  diagnoses_according_to_icd10: "diagnoses_according_to_icd10",
  codesbody_functions_according_to_ICF: "codesbody_functions_according_to_icf",
  participation_codes: "participation_codes",
  disability: "disability",
  icf_qualifier: "icf_qualifier",
  indicator: "indicator",
  codes_facilitator_and_barrier: "codes_facilitator_and_barrier",
};

const codeSetupTypesOfProtection = {
  social_protection: "social_protection",
  child_protection: "child_protenction",
};

const specialistTypes = {
  social_worker: "social_worker",
  psychologist: "psychologist",
  special_educator_and_rehabilitator: "special_educator_and_rehabilitator",
};

const regionsStatus = {
  active: "active",
  not_active: "not_active",
};

const genderTypes = {
  Машко: "Машко",
  Женско: "Женско",
};

const referenceConfigs = {
  regions: {
    id: "id",
    label: "name",
    type: referenceTypes.remote,
    table: "regions",
  },
  municipalities: {
    id: "name",
    label: "name",
    type: referenceTypes.remote,
    table: "municipalities",
    groupBy: "region_id",
  },
  users: {
    id: "id",
    label: (item) => {
      return `${item.first_name} ${item.last_name}`;
    },
    type: referenceTypes.remote,
    table: "users",
  },
  languages: {
    id: "code",
    label: "name",
    type: referenceTypes.remote,
    table: "languages",
  },
  view_facilitator_and_barrier_indicator: {
    id: "code",
    label: "code",
    type: referenceTypes.remote,
    table: "view_facilitator_and_barrier_indicator",
  },
  view_facilitator_and_barrier_qualifier: {
    id: "code",
    label: "code",
    type: referenceTypes.remote,
    table: "view_facilitator_and_barrier_qualifier",
  },
  code_setup: {
    id: "id",
    label: "code",
    type: referenceTypes.remote,
    table: "code_setup",
  },
  user_types: {
    id: "id",
    label: (item) => {
      const name = item.name;
      return global.UF.tr(name);
    },
    type: referenceTypes.remote,
    table: "user_types",
  },
  view_code_setup_institution_health: {
    id: "id",
    label: "description",
    type: referenceTypes.remote,
    table: "view_code_setup_institution_health",
  },
  view_code_setup_institution_social: {
    id: "id",
    label: "description",
    type: referenceTypes.remote,
    table: "view_code_setup_institution_social",
  },
  view_code_setup_child_support_measures: {
    id: "id",
    label: "description",
    type: referenceTypes.remote,
    table: "view_code_setup_child_support_measures",
  },
  view_code_setup_codes_facilitator_and_barrier: {
    id: "id",
    label: "code",
    type: referenceTypes.remote,
    table: "view_code_setup_codes_facilitator_and_barrier",
  },
  view_code_setup_codesbody_functions_according_to_icf: {
    id: "id",
    label: "code",
    type: referenceTypes.remote,
    table: "view_code_setup_codesbody_functions_according_to_icf",
  },
  view_code_setup_diagnoses_according_to_icd10: {
    id: "id",
    label: (item) => {
      const code_description = `${item.code} - ${item.description}`;
      return code_description;
    },
    type: referenceTypes.remote,
    table: "view_code_setup_diagnoses_according_to_icd10",
  },
  view_code_setup_disability: {
    id: "id",
    label: "description",
    type: referenceTypes.remote,
    table: "view_code_setup_disability",
  },
  view_code_setup_health_care_measures: {
    id: "id",
    label: "description",
    type: referenceTypes.remote,
    table: "view_code_setup_health_care_measures",
  },
  view_code_setup_icf_qualifier: {
    id: "id",
    label: "code",
    type: referenceTypes.remote,
    table: "view_code_setup_icf_qualifier",
  },
  view_code_setup_indicator: {
    id: "id",
    label: "code",
    type: referenceTypes.remote,
    table: "view_code_setup_indicator",
  },
  view_code_setup_social_protection_measures: {
    id: "id",
    label: "description",
    type: referenceTypes.remote,
    table: "view_code_setup_social_protection_measures",
  },
  view_code_setup_participation_codes: {
    id: "id",
    label: "code",
    type: referenceTypes.remote,
    table: "view_code_setup_participation_codes",
  },
  patients: {
    id: "id",
    label: (dataItem) => {
      return `${dataItem.first_name} ${dataItem.fathers_name} ${dataItem.last_name}`;
    },
    type: referenceTypes.remote,
    table: "patients",
  },

  specialistTypes: {
    type: referenceTypes.local,
    source: specialistTypes,
    localization: true,
  },
  yesNo: {
    type: referenceTypes.local,
    source: yesNo,
  },
  usersStatus: {
    type: referenceTypes.local,
    source: usersStatus,
    localization: true,
  },
  regionsStatus: {
    type: referenceTypes.local,
    source: regionsStatus,
    localization: true,
  },
  genderTypes: {
    type: referenceTypes.local,
    source: genderTypes,
  },
  codeSetupCategories: {
    type: referenceTypes.local,
    source: codeSetupCategories,
    localization: true,
  },
  codeSetupTypesOfProtection: {
    type: referenceTypes.local,
    source: codeSetupTypesOfProtection,
    localization: true,
  },
  subjectStatuses: {
    type: referenceTypes.local,
    source: subjectStatuses,
  },
  subjectTypes: {
    type: referenceTypes.local,
    source: subjectTypes,
  },
};

class DataReferences {
  references = {};
  reference_configs = referenceConfigs;
  data_provider = null;

  constructor(data_provider) {
    this.data_provider = data_provider;

    Object.keys(referenceConfigs).forEach((key) => {
      if (referenceConfigs[key].type == referenceTypes.local) {
        this.references[key] = [];
        Object.keys(referenceConfigs[key].source).forEach((item) => {
          this.references[key].push({
            id: item,
            label: referenceConfigs[key].localization
              ? global.UF.tr(referenceConfigs[key].source[item])
              : referenceConfigs[key].source[item],
          });
        });
      }
    });
  }

  search = (reference, search) => {
    const data = this.references[reference];
    if (data) {
      let tempData = data.map((ref) => {
        if (ref.label.toLowerCase().includes(search.toLowerCase())) {
          return `'${ref.id.toString()}'`;
        } else {
          return null;
        }
      });
      tempData = tempData.filter((ref) => ref !== null);
      return tempData;
    }
  };

  getReferenceLabel = (reference, value) => {
    let label = "";
    if (reference !== null && this.references[reference] && value !== null) {
      label = this.references[reference].find((ref) => ref.id == value)
        ? this.references[reference].find((ref) => ref.id == value).label
        : "";
    }
    return label;
  };

  get = (
    reference,
    callBack,
    force = false,
    initialFilters = null,
    filterFunction = null
  ) => {
    if (
      this.references[reference] &&
      (force == false ||
        referenceConfigs[reference].type == referenceTypes.local)
    ) {
      setTimeout(() => {
        callBack(this.references[reference]);
      }, 100);
    } else {
      let filters = initialFilters;
      if (!filters) {
        filters = referenceConfigs[reference].filters
          ? referenceConfigs[reference].filters
          : {};
      }

      const table = referenceConfigs[reference].table;
      this.data_provider.get(table, filters, (data) => {
        let mappedData = data.map((item) => {
          const newItem = {
            id: item[referenceConfigs[reference].id],
            label:
              typeof referenceConfigs[reference].label === "function"
                ? referenceConfigs[reference].label(item)
                : item[referenceConfigs[reference].label],
          };

          if (
            referenceConfigs[reference].hasOwnProperty("additional_fields") &&
            Array.isArray(referenceConfigs[reference]["additional_fields"])
          ) {
            referenceConfigs[reference]["additional_fields"].forEach(
              (field) => {
                newItem[field] = item[field];
              }
            );
          }

          if (referenceConfigs[reference].hasOwnProperty("groupBy")) {
            if (
              referenceConfigs[reference].hasOwnProperty("groupByException")
            ) {
              if (
                !referenceConfigs[reference][`groupByException`].includes(
                  newItem.label
                )
              ) {
                newItem.group = item[referenceConfigs[reference].groupBy];
              }
            } else {
              newItem.group = item[referenceConfigs[reference].groupBy];
            }
          }
          return newItem;
        });
        if (filterFunction) {
          mappedData = filterFunction(mappedData);
        }
        this.references[reference] = mappedData;
        callBack(this.references[reference]);
      });
    }
  };
}

export default DataReferences;
