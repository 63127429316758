import React from "react";
import "../Login/log_in.scss";
import { v4 as uuidv4 } from "uuid";
import Nx_Ctrl_TextInput from "../../../nix_components/ui_components/controls/nx_ctrl_textinput.js";
import withRouter from "../../../nix_components/components/withRouter.js";
import { validators } from "../../../nix_components/data/data_validators.js";
import Nx_Button from "../../../nix_components/ui_components/controls/nx_button.js";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      is_error: false,
      error_message: "",
      info_text: "",
      user: null,
    };
  }

  validateEmail = () => {
    const validatorsArr = [
      validators.isNull,
      validators.isEmpty,
      validators.isNotEmail,
    ];

    for (const validator of validatorsArr) {
      if (validator.func(this.state.email)) {
        this.setState({
          is_error: true,
          error_message: validator.msg,
        });
        return false;
      }
    }
    return true;
  };

  validateEmailExistance = async () => {
    if (!this.validateEmail()) {
      return false;
    }

    try {
      const users = await global.UF.dataProvider.get_v2(`users`, {
        email: this.state.email,
      });

      if (!users.length) {
        this.setState({
          error_message: global.UF.tr("user_with_that_email_doesnt_exist"),
          is_error: true,
        });
        return false;
      }

      this.setState({
        is_error: false,
        error_message: "",
        user: users[0],
      });
      return users[0];
    } catch (err) {
      console.error(err);
      global.UF.setAlertVisibility(true, err.toString(), "error");
    }
  };

  handleForgotPassword = async () => {
    const user = await this.validateEmailExistance();
    if (!user) {
      return;
    }

    const uuid = uuidv4();

    const body = {
      id: user.id,
      uuid: uuid,
      from: "noreply@zdravstvendom.com",
      // from: "dev@tgn.mk",
      to: this.state.email,
      subject: "Захтев за промене лозинке!",
      text: "",
      html: `<div>Ако сакате да ја промените вашата лозинка кликнете <a href="${window.location.origin}/reset-password?id=${uuid}">ОВДЕ</a></div>`,
    };

    const response = await global.UF.makeRequest_v2(
      `POST`,
      `/api/forgot-password`,
      body,
      false
    );

    if (!response.ok) {
      console.log(response, "response");
      return;
    }

    this.setState({ info_text: "Ве молам проверете го вашиот емаил" });
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.handleForgotPassword();
    }
  };

  render() {
    return (
      <div className="uf_login">
        <div className="uf_login_card">
          <div className="uf_login_logo_section">
            <div className="uf_login_logo">
              <img src={`./assets/images/defektolozi.png`} alt={"logo"} />
            </div>
          </div>
          <div
            className="uf_login_login"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div className="uf_login_text">Заборавена лозинка</div>
            <div className="uf_login_textfield_wrapper">
              <Nx_Ctrl_TextInput
                onChange={(email) => this.setState({ email })}
                label={global.UF.tr("email_address")}
                error={this.state.error_message.length > 0}
                helperText={this.state.error_message}
                value={this.state.email}
                onKeyDown={this.onKeyDown}
                inputStyle={{ height: "25px", borderRadius: "10px" }}
              />
            </div>
            <div className={"uf_login_button_container"}>
              <Nx_Button
                label={`Испрати емаил`}
                variant={"contained"}
                onClick={this.handleForgotPassword}
              />
              <Nx_Button
                label={global.UF.tr("log_in")}
                variant={"text"}
                onClick={() => {
                  this.props.router.navigate("/");
                }}
              />
            </div>
            <br />
            <span
              style={{
                display: this.state.info_text.length ? "block" : "none",
                fontSize: "15px",
                fontFamily: "Poppins",
                fontWeight: "500",
                textAlign: "center",
                color: "rgba(45, 93, 252, 0.90)",
                marginTop: "-5px",
              }}
            >
              {this.state.info_text}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ForgotPassword);
