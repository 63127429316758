import React from "react";
import UF_Content from "../../../nix_components/components/UF_Content";
import { usersTypes } from "../../../nix_components/data/DataReferences";
import withRouter from "../../../nix_components/components/withRouter";
import Patient from "./Patient";

class Patients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      patient_id: null,
      patient: null,
    };
    this.handleRegionalAdmin();
    global.UF.dataProvider.datastructure["patients"].filters = {
      // custom_filter: "deleted_at is null",
    };
  }

  buttons = {};

  formConfig = {
    rows: [
      ["id_number", "gender"],
      ["first_name", "fathers_name", "last_name"],
      ["date_of_birth", "address", "city"],
      [
        {
          field: "region_id",
          disabled: !["super_admin", "regional_admin"].includes(
            usersTypes[this.props.user.user_type_id]
          ),
          onChange: () => {
            this.forceUpdate();
          },
        },
        "birth_place",
      ],
      ["community", "living_place", "zip_code"],
      ["contact_phone", "contact_email"],
      ["contact_name", "contact_relationship"],
    ],
  };

  formConfigEdit = {
    rows: [
      [
        { field: "id_number", disabled: true },
        { field: "gender", disabled: true },
      ],
      [
        { field: "first_name", disabled: true },
        { field: "fathers_name", disabled: true },
        { field: "last_name", disabled: true },
      ],
      [
        { field: "date_of_birth", disabled: true },
        { field: "address", disabled: true },
        { field: "city", disabled: true },
      ],
      [
        { field: "region_id", disabled: true },
        { field: "birth_place", disabled: true },
      ],
      [
        { field: "community", disabled: true },
        { field: "living_place", disabled: true },
        { field: "zip_code", disabled: true },
      ],
      ["contact_phone", "contact_email"],
      ["contact_name", "contact_relationship"],
    ],
  };

  gridProps = {
    fields: [
      "first_name",
      "fathers_name",
      "last_name",
      "id_number",
      "region_id",
      "community",
    ],
  };

  handleRegionalAdmin = () => {
    if (usersTypes[this.props.user.user_type_id] === "regional_admin") {
      global.UF.dataProvider.referenceProvider.get(`regions`, (regions) => {
        const user_regions = JSON.parse(this.props.user.region_id);
        const filtered_regions = regions.filter((region) =>
          user_regions.includes(JSON.stringify(region.id))
        );

        this.formConfig = {
          rows: [
            ["id_number", "gender"],
            ["first_name", "fathers_name", "last_name"],
            ["date_of_birth", "address", "city"],
            [
              {
                field: "region_id",
                disabled: false,
                data: filtered_regions,
                reference: null,
                onChange: () => {
                  this.forceUpdate();
                },
              },
              "birth_place",
            ],
            ["community", "living_place", "zip_code"],
            ["contact_phone", "contact_email"],
            ["contact_name", "contact_relationship"],
          ],
        };

        this.forceUpdate();
      });
    }
  };

  // onSave = async (dataItem, callback) => {
  //   if (dataItem.isNew === false) {
  //     dataItem.Save(() => {
  //       if (callback) {
  //         callback();
  //       }
  //     });
  //     return;
  //   }
  //   const uuid = uuidv4();
  //   const region_id = dataItem.region_id;
  //   if (!region_id) {
  //     return;
  //   }

  //   const patient = { ...dataItem.GetChangedFields() };

  //   try {
  //     dataItem.id = uuid;

  //     dataItem.Save(async (res) => {
  //       if (res.hasOwnProperty("error")) {
  //         global.UF.handleError(res.error);
  //         return;
  //       }
  //       patient.id = dataItem.id;

  //       const response = await global.UF.makeRequest_v2(
  //         `POST`,
  //         `/api/patient/create_record`,
  //         { patient }
  //       );

  //       if (!response.ok) {
  //         global.UF.handleError(response);
  //         return;
  //       }

  //       if (callback) {
  //         callback();
  //       }
  //     });
  //   } catch (err) {
  //     global.UF.handleError(err);
  //     return;
  //   }
  // };

  handleRowClick = (params) => {
    if (!params || !params.hasOwnProperty("id")) {
      return;
    }

    const { row } = params;
    this.setState({ patient: row, patient_id: params.id }, () => {
      this.props.router.navigate(`/patients/${params.id}`);
    });
  };

  componentDidMount = async () => {
    await this.handleFetchingPatient(this.props.router);
  };

  shouldComponentUpdate = async (nextProps) => {
    const params = this.props.router.params;
    const nextParams = nextProps.router.params;

    if (Object.keys(params).length != Object.keys(nextParams).length) {
      if (!nextParams.hasOwnProperty("id")) {
        this.setState({ patient_id: null, patient: null });
        return true;
      }

      await this.handleFetchingPatient(nextProps.router);

      this.setState({ patient_id: nextParams.id });
    }
    return true;
  };

  handleFetchingPatient = async (router) => {
    const { params } = router;
    if (!params.hasOwnProperty("id") && !params.id) {
      return;
    }

    const patient_id = params.id;
    const patients = await global.UF.dataProvider.get_v2(`patients`, {
      id: patient_id,
    });

    if (!patients.length) {
      global.UF.setAlertVisibility(
        true,
        "Не постоји пациент са тим ID",
        "warning"
      );
      router.navigate("/patients");
      return;
    }

    const patient = patients[0];
    this.setState({ patient_id, patient });
  };

  onAdd = () => {
    const patient = global.UF.dataProvider.datastructure["patients"].new();
    if (
      !["super_admin", "regional_admin"].includes(
        usersTypes[this.props.user.user_type_id]
      )
    ) {
      patient.region_id = JSON.parse(this.props.user.region_id);
    }
    return patient;
  };

  onDelete = async (deleteItem) => {
    deleteItem.Delete(() => {});

    //If we want soft delete uncomment this
    // deleteItem.deleted_at = new Date();
    // deleteItem.Save(() => {});
  };

  renderPatients = () => {
    if (this.state.patient_id) {
      return (
        <Patient
          id={this.state.patient_id}
          dataItem={this.state.patient}
          user={this.props.user}
          handleClose={() => this.setState({ patient_id: null, patient: null })}
        />
      );
    }
    return (
      <UF_Content
        table="patients"
        title="Преглед и управување со пациенти"
        formTitle="Пациент"
        gridProps={this.gridProps}
        buttons={this.buttons}
        edit={this.props.edit}
        onRowClick={this.handleRowClick}
        onDelete={this.onDelete}
        formConfigAdd={this.formConfig}
        formConfig={this.formConfigEdit}
        add={this.props.add}
        onAdd={this.onAdd}
        {...this.props}
      />
    );
  };

  render() {
    return <>{this.renderPatients()}</>;
  }
}

export default withRouter(Patients);
