import React from "react";
import UF_Content from "../../../nix_components/components/UF_Content";

class Municipalities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  buttons = {};

  formConfig = {
    rows: [["name", "region_id"]],
  };

  gridProps = {
    fields: ["name", "region_id", "created_at"],
  };

  render() {
    return (
      <>
        <UF_Content
          table="municipalities"
          title="Листа на општини на територија на Република С. Македонија"
          formTitle="Општина"
          gridProps={this.gridProps}
          buttons={this.buttons}
          edit={true}
          formConfig={this.formConfig}
          add={true}
          {...this.props}
        />
      </>
    );
  }
}

export default Municipalities;
