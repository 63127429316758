import React from "react";
import UF_Content from "../../../nix_components/components/UF_Content";
import data_types from "../../../nix_components/data/data_types";
import { validators } from "../../../nix_components/data/data_validators";

class CodeSetup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: this.props.category,
      label: "",
    };
    global.UF.dataProvider.datastructure["code_setup"].filters = {
      category: this.state.category,
    };
    this.buttons = {};

    this.formConfig = {};

    this.gridProps = {};

    this.handleLabelsNames();
  }

  handleLabelsNames = () => {
    let fieldConfig = [];
    switch (this.state.category) {
      case "child_support_measures":
        fieldConfig = [
          [
            {
              field: "description",
              label: "Опис",
              datatype: data_types.string,
              multiline: true,
              validators: [validators.isNull, validators.isEmpty],
            },
          ],
        ];
        this.gridProps = {
          fields: ["description"],
        };
        break;
      case "social_protection_measures":
        fieldConfig = [
          [
            {
              field: "type_of_protection",
              label: "Тип на заштита",
              datatype: data_types.reference,
              reference: "codeSetupTypesOfProtection",
            },
            {},
          ],
          [
            {
              field: "description",
              label: "Опис",
              datatype: data_types.string,
              multiline: true,
              validators: [validators.isNull, validators.isEmpty],
            },
          ],
        ];
        this.gridProps = {
          fields: ["description", "type_of_protection"],
        };
        break;
      case "health_care_measures":
        fieldConfig = [
          [
            {
              field: "description",
              label: "Опис",
              datatype: data_types.string,
              multiline: true,
              validators: [validators.isNull, validators.isEmpty],
            },
          ],
        ];
        this.gridProps = {
          fields: ["description"],
        };
        break;
      case "diagnoses_according_to_icd10":
        fieldConfig = [
          [
            {
              field: "code",
              label: "Шифра по MKB10",
              datatype: data_types.string,
              validators: [validators.isNull, validators.isEmpty],
            },
            {},
          ],
          [
            {
              field: "description",
              label: "Дијагноза по МКБ-10",
              datatype: data_types.string,
              multiline: true,
              validators: [validators.isNull, validators.isEmpty],
            },
          ],
        ];
        this.gridProps = {
          fields: [
            { field: "code", label: "Шифра по MKB10" },
            { field: "description", label: "Дијагноза по МКБ-10" },
          ],
        };
        break;
      case "codesbody_functions_according_to_ICF":
        fieldConfig = [
          [
            {
              field: "code",
              label: "МКФ код",
              datatype: data_types.string,
              validators: [validators.isNull, validators.isEmpty],
            },
          ],
        ];
        this.gridProps = {
          fields: [{ field: "code", label: "МКФ код" }],
        };
        break;
      case "participation_codes":
        fieldConfig = [
          [
            {
              field: "code",
              label: "Учество",
              datatype: data_types.string,
              validators: [validators.isNull, validators.isEmpty],
            },
          ],
        ];
        this.gridProps = {
          fields: [{ field: "code", label: "Учество" }],
        };
        break;
      case "disability":
        fieldConfig = [
          [
            {
              field: "description",
              label: "Опис",
              datatype: data_types.string,
              multiline: true,
              validators: [validators.isNull, validators.isEmpty],
            },
          ],
        ];
        this.gridProps = {
          fields: ["description"],
        };
        break;
      case "icf_qualifier":
        fieldConfig = [
          [
            {
              field: "code",
              label: "Квалификатор",
              datatype: data_types.string,
              validators: [validators.isNull, validators.isEmpty],
            },
          ],
        ];
        this.gridProps = {
          fields: [{ field: "code", label: "Квалификатор" }],
        };
        break;
      case "indicator":
        fieldConfig = [
          [
            {
              field: "code",
              label: "Индикатор",
              datatype: data_types.string,
              validators: [validators.isNull, validators.isEmpty],
            },
          ],
        ];
        this.gridProps = {
          fields: [{ field: "code", label: "Индикатор" }],
        };
        break;
      case "codes_facilitator_and_barrier":
        fieldConfig = [
          [
            {
              field: "code",
              label: "Олеснувач и Бариера",
              datatype: data_types.string,
              validators: [validators.isNull, validators.isEmpty],
            },
          ],
        ];
        this.gridProps = {
          fields: [{ field: "code", label: "Олеснувач и Бариера" }],
        };
        break;
      default:
        fieldConfig = [
          [
            {
              field: "code",
              label: "Kод",
              datatype: data_types.string,
              validators: [validators.isNull, validators.isEmpty],
            },
          ],
          [
            {
              field: "description",
              label: "Опис",
              datatype: data_types.string,
              multiline: true,
              validators: [validators.isNull, validators.isEmpty],
            },
          ],
        ];
        this.gridProps = {
          fields: ["code", "description"],
        };
        break;
    }
    this.formConfig = {
      rows: fieldConfig,
    };
    this.forceUpdate();
  };

  shouldComponentUpdate = (nextProps) => {
    if (nextProps.category != this.props.category) {
      this.setState({ category: nextProps.category });
    }

    return true;
  };

  onAdd = () => {
    const dataItem = global.UF.dataProvider.datastructure["code_setup"].new();
    dataItem.category = this.state.category;
    return dataItem;
  };

  render() {
    return (
      <>
        <UF_Content
          table="code_setup"
          title={`Листа на ${global.UF.tr(this.state.category).toLowerCase()}`}
          formTitle={`${global.UF.tr(this.state.category).toLowerCase()}`}
          gridProps={this.gridProps}
          buttons={this.buttons}
          edit={true}
          formConfig={this.formConfig}
          onDataItemsLoad={this.onDataItemsLoad}
          onBeforeDataItemSave={this.onBeforeDataItemSave}
          onAdd={this.onAdd}
          add={true}
          {...this.props}
          {...this.props}
        />
      </>
    );
  }
}

export default CodeSetup;
