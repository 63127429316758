import React, { Fragment } from "react";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import { ListItemIcon } from "@mui/material";

export default function ListItem(props) {
  return (
    <Fragment>
      <ListItemButton
        onClick={props.onClick}
        sx={props.sx}
        color={"#FFF"}
        className={props.active ? "menu_item menu_item_active" : "menu_item"}
      >
        {props.icon ? (
          <ListItemIcon sx={{ color: props.active ? "#FFF" : "#A5A7AF" }}>
            {props.icon}
          </ListItemIcon>
        ) : null}

        <ListItemText primary={props.title} />
      </ListItemButton>
    </Fragment>
  );
}
