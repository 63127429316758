import request from "superagent";
import { t } from "i18next";
import bcrypt from "bcryptjs-react";

class UF {
  constructor() {
    this.dataProvider = null;
  }

  makeRequest = (
    type,
    url,
    data,
    includeCredentials,
    success,
    errorcb,
    showLoading = true,
    showAlert = true
  ) => {
    let message = "";
    let req = null;
    switch (type) {
      case "GET":
        req = request.get(url);
        break;
      case "POST":
        req = request.post(url);
        message = "Успешно";
        break;
      case "PUT":
        req = request.put(url);
        message = "Успешно";
        break;
      case "DEL":
        req = request.del(url);
        message = "Успешно";
        break;
      default:
    }

    if (includeCredentials) {
      req = req.withCredentials();
    }

    if (data) {
      if (type == "GET") {
        req = req.query(data);
      } else {
        req = req.send(data);
      }
    }

    req = req.set(
      "Access-Control-Allow-Origin",
      global.location.protocol + "//" + global.location.host
    );

    if (includeCredentials) {
      req = req.set("authorization", "Bearer " + localStorage.getItem("token"));
    }
    //req.type('application/json').then(success, error);
    req.type("application/json").then(
      (data) => {
        if (showLoading) {
          this.setMaskVisibility(false);
        }
        if (type !== "GET" && showAlert && includeCredentials) {
          this.setAlertVisibility(true, message, "success");
        }
        success(data);
      },
      (err) => {
        let error = err;
        if (err && err.response && err.response.body) {
          error = {
            code: err.response.body.code,
            error: err.response.body.error,
          };
        }

        if (err.status === 401) {
          this.logout();
        }
        if (err) {
          this.setMaskVisibility(false);
          if (showAlert) {
            this.setAlertVisibility(
              true,
              error.error ? error.error : err.toString(),
              "error"
            );
          }
        }
        errorcb(error);
      }
    );

    if (showLoading) {
      this.setMaskVisibility(true);
    }
  };

  makeRequest_v2 = (
    type,
    url,
    data,
    includeCredentials,
    showLoading = true,
    showAlert = true
  ) => {
    return new Promise(async (resolve, reject) => {
      let message = "";
      let req = null;

      switch (type) {
        case "GET":
          req = request.get(url);
          break;
        case "POST":
          req = request.post(url);
          message = "Успешно";
          break;
        case "PUT":
          req = request.put(url);
          message = "Успешно";
          break;
        case "DEL":
          req = request.del(url);
          message = "Успешно";
          break;
        default:
      }

      if (includeCredentials) {
        req = req.withCredentials();
      }

      if (data) {
        if (type == "GET") {
          req = req.query(data);
        } else {
          req = req.send(data);
        }
      }

      req = req.set(
        "Access-Control-Allow-Origin",
        global.location.protocol + "//" + global.location.host
      );

      if (includeCredentials) {
        req = req.set(
          "authorization",
          "Bearer " + localStorage.getItem("token")
        );
      }

      if (showLoading) {
        this.setMaskVisibility(true);
      }

      try {
        const data = await req.type("application/json");
        if (showLoading) {
          this.setMaskVisibility(false);
        }
        if (type !== "GET" && showAlert && includeCredentials) {
          this.setAlertVisibility(true, message, "success");
        }
        resolve(data);
      } catch (err) {
        const error = err.response.body;
        if (err) {
          this.setMaskVisibility(false);
        }
        if (showAlert) {
          this.setAlertVisibility(
            true,
            error ? error.error : err.toString(),
            "error"
          );
        }
        if (err.status === 401) {
          this.logout();
        }
        reject(error);
      }
    });
  };

  makeRequest2 = (type, url, data, includeCredentials, success, error) => {
    let req = null;
    switch (type) {
      case "GET":
        req = request.get(url);
        break;
      case "POST":
        req = request.post(url);
        break;
      case "PUT":
        req = request.put(url);
        break;
      case "DEL":
        req = request.del(url);
        break;
      default:
    }

    if (includeCredentials) {
      req = req.withCredentials();
    }

    if (data) {
      req = req.send(data);
    }

    req = req.set(
      "Access-Control-Allow-Origin",
      global.location.protocol + "//" + global.location.host
    );

    if (includeCredentials) {
      req = req.set("authorization", "Bearer " + localStorage.getItem("token"));
    }
    req.type("application/json").then(
      (data) => {
        this.setMaskVisibility(false);
        success(data);
      },
      (err) => {
        if (err.status === 401) {
          this.logout();
        }
        if (err) {
          this.setMaskVisibility(false);
        }
        error(err);
      }
    );

    this.setMaskVisibility(true);
  };

  logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("event_settings");
    window.location.href = "/";
  };

  setAlertVisibility = (show, message, type) => {
    if (global.setAlertVisibility) {
      global.setAlertVisibility(show, message, type);
    }
  };

  setProgressiveLoaderVisiblity = async (
    show,
    table,
    id,
    onSuccess = () => {},
    onError = () => {},
    className
  ) => {
    if (global.progressLoadingSetVisibility) {
      await global.progressLoadingSetVisibility(
        show,
        table,
        id,
        onSuccess,
        onError,
        className
      );
    }
  };

  setMaskVisibility = (visible) => {
    if (global.loadingSetVisibility) {
      global.loadingSetVisibility(visible);
    }
  };

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  cryptPassword = (password) => {
    try {
      const salt = bcrypt.genSaltSync(10);
      const hash = bcrypt.hashSync(password, salt);
      return hash;
    } catch (err) {
      console.error(`Error occurred while trying to crypt password : ${err}`);
      this.setAlertVisibility(true, err.toString(), "error");
    }
  };

  handleError = (error) => {
    console.error(`error`, error);
    if (error instanceof Error) {
      global.UF.setAlertVisibility(true, error.toString(), "error");
    }
    if (error.hasOwnProperty("error")) {
      global.UF.setAlertVisibility(true, error.error, "error");
    }
  };

  tr = t;

  // changeLanguage = changeLanguage;
}

export default UF;
