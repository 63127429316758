import React, { Component } from "react";
import Nx_Dialog from "../../../../../nix_components/ui_components/dialogs/nx_dialog";
import Grid from "../../../../../nix_components/ui_components/layouts/Grid/Grid";
import Nx_Ctrl_TextInput from "../../../../../nix_components/ui_components/controls/nx_ctrl_textinput";
import Nx_Ctrl_Select from "../../../../../nix_components/ui_components/controls/nx_ctrl_select";
import { usersTypes } from "../../../../../nix_components/data/DataReferences";

export default class TransferPatientRegion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_region: props.current_region,
      future_region: null,
      future_region_error: null,
      available_regions: [],
      transfer_reason: null,
      transfer_reason_error: null,
    };
    this.handleAvailableRegions();
  }

  buttons = [
    {
      label: "Откажи",
      variant: "text",
      onClick: this.props.handleClose,
    },
    {
      label: "Сочувај",
      variant: "text",
      onClick: () => this.handleSavingTransfer(),
    },
  ];

  handleAvailableRegions = () => {
    const { user } = this.props;
    global.UF.dataProvider.referenceProvider.get(`regions`, (regions) => {
      regions = regions.filter(
        (region) => region.label != this.props.current_region
      );
      if (usersTypes[user.user_type_id] === "super_admin") {
        this.setState({ available_regions: regions });
        return;
      }
      const user_regions = JSON.parse(user.region_id);
      const filtered_regions = regions.filter((region) =>
        user_regions.includes(JSON.stringify(region.id))
      );
      this.setState({ available_regions: filtered_regions });
    });
  };

  handleFutureRegionChange = (value) => {
    this.setState({ future_region: value });
  };

  handleReasonChange = (value) => {
    this.setState({ transfer_reason: value });
  };

  handleSavingTransfer = async () => {
    const patients = await global.UF.dataProvider.get_v2(`patients`, {
      id: this.props.patient_id,
    });

    if (!patients.length) {
      global.UF.setAlertVisibility(
        true,
        "Не постои пациент со тој ИД број!",
        "error"
      );
      return;
    }

    const patient = patients[0];

    const transfer_record =
      global.UF.dataProvider.datastructure["transfer_history"].new();
    transfer_record.patient_id = patient.id;
    transfer_record.patient_fullname = `${patient.first_name} ${patient.fathers_name} ${patient.last_name}`;
    transfer_record.old_region_id = patient.region_id;
    transfer_record.new_region_id = this.state.future_region;
    transfer_record.transfer_reason = this.state.transfer_reason;

    if (!Boolean(transfer_record.new_region_id)) {
      this.setState({ future_region_error: "Морате изабрати будући регион!" });
      return;
    }

    if (!Boolean(transfer_record.transfer_reason?.trim())) {
      this.setState({
        transfer_reason_error: "Разлог трансфера је обавезно поље!",
      });
      return;
    }

    transfer_record.Save(() => {
      patient.region_id = this.state.future_region;
      patient.Save(() => {
        this.props.handleClose();
      });
    });
  };

  render() {
    return (
      <Nx_Dialog
        open={this.props.open}
        handleClose={this.props.handleClose}
        width="750px"
        title={`Прераспределба на пациент во друг регион`}
        buttons={this.buttons}
      >
        <Grid style={{ gap: "30px", marginTop: "30px" }}>
          <Grid
            style={{
              gridAutoFlow: "column",
              gap: "20px",
              gridTemplateColumns: "repeat(2, 1fr)",
            }}
          >
            <Nx_Ctrl_TextInput
              type={"text"}
              label={"Тековен регион"}
              value={this.state.current_region}
              disabled={true}
            />
            <Nx_Ctrl_Select
              data={this.state.available_regions}
              onChange={this.handleFutureRegionChange}
              fieldConfig={{ label: "Прераспределба во регион" }}
              value={this.state.future_region}
              helperText={this.state.future_region_error}
              error={Boolean(this.state.future_region_error)}
            />
          </Grid>
          <Nx_Ctrl_TextInput
            type={"text"}
            fieldConfig={{
              multiline: true,
              label: "Причина поради која што се префрла пациентот:",
            }}
            error={Boolean(this.state.transfer_reason_error)}
            helperText={this.state.transfer_reason_error}
            onChange={this.handleReasonChange}
            label={"Причина поради која што се префрла пациентот:"}
            value={this.state.transfer_reason}
          />
        </Grid>
      </Nx_Dialog>
    );
  }
}
