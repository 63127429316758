import React, { Component, Fragment } from "react";
import {
  UI_Form,
  UI_Form_Row,
  UF_Form_Cell,
} from "./form_wrappers/controls/nx_form_controls";
import Nx_Ctrl_Generic from "../ui_components/controls/nx_ctrl_generic";
import Grid from "../ui_components/layouts/Grid/Grid";
import Nx_Button from "../ui_components/controls/nx_button";

export default class NxGridView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataItem: props.dataItem,
    };
    const { viewConfig, table } = props;
    this.viewConfig = viewConfig;
    this.dataConfigs = global.UF.dataProvider.datastructure[table];
  }

  renderRow = (row, index) => {
    if (row && row.length) {
      return (
        <Fragment key={index}>
          <UI_Form_Row>
            {row.map((field, index) =>
              this.renderField(field, row.length, index)
            )}
          </UI_Form_Row>
        </Fragment>
      );
    }
    return null;
  };

  renderField = (field, col, index) => {
    const tempField = field.hasOwnProperty("length")
      ? this.dataConfigs.fields[field]
      : { ...this.dataConfigs.fields[field.field], ...field };
    return (
      <Fragment key={index}>
        <UF_Form_Cell cols={col} read_only={true}>
          <Nx_Ctrl_Generic
            read_only={true}
            dataItem={this.state.dataItem}
            fieldConfig={tempField}
          />
        </UF_Form_Cell>
      </Fragment>
    );
  };

  renderButtons = () => {
    const { buttons } = this.props;
    if (!buttons.length) {
      return null;
    }

    return buttons.map((button) => {
      return <Nx_Button {...button} />;
    });
  };

  render() {
    return (
      <Grid>
        <UI_Form read_only={true}>
          <Grid
            style={{
              gridAutoFlow: "column",
              alignItems: "center",
              maxHeight: "80px",
            }}
          >
            <div className="uf_content_title">
              Податоци на {this.props.title}
            </div>
            {this.renderButtons()}
          </Grid>
          {this.viewConfig?.rows.map((row, index) =>
            this.renderRow(row, index)
          )}
        </UI_Form>
      </Grid>
    );
  }
}
