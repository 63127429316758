import React from "react";
import UF_Content from "../../nix_components/components/UF_Content";

export default function MySubjects({ user, router }) {
  global.UF.dataProvider.datastructure["subjects"].filters = {
    doctor_id: user.id,
  };

  const gridProps = {
    fields: [
      "delovoden_broj",
      "subject_type",
      "created_by",
      "status",
      "created_at",
    ],
  };

  const handleRowClick = (row) => {
    const subject = row.row;
    router.navigate(`/patients/${subject.patient_id}`);
  };

  return (
    <UF_Content
      table="subjects"
      title="Преглед и управување со мои предмети"
      gridProps={gridProps}
      onRowClick={handleRowClick}
      buttons={{}}
      edit={false}
      add={false}
    />
  );
}
