import React from "react";
import "./log_in.scss";
import withRouter from "../../../nix_components/components/withRouter.js";
import Nx_Ctrl_TextInput from "../../../nix_components/ui_components/controls/nx_ctrl_textinput.js";
import Nx_Button from "../../../nix_components/ui_components/controls/nx_button.js";
import { validators } from "../../../nix_components/data/data_validators.js";

class LoginScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      is_error: false,
      email_error_message: "",
      password_error_message: "",
    };
  }

  onChange = (val, field) => {
    this.state[field] = val;
    this.forceUpdate();
  };

  validatePassword = (password) => {
    const validatorsArr = [validators.isNull, validators.isEmpty];

    for (const validator of validatorsArr) {
      if (validator.func(password)) {
        this.setState({
          is_error: true,
          password_error_message: validator.msg,
        });
        return false;
      }
    }
    return true;
  };

  validateEmail = (email) => {
    const validatorsArr = [
      validators.isNull,
      validators.isEmpty,
      validators.isNotEmail,
    ];

    for (const validator of validatorsArr) {
      if (validator.func(email)) {
        this.setState({
          is_error: true,
          email_error_message: validator.msg,
        });
        return false;
      }
    }
    return true;
  };

  handleLogin = async () => {
    const valid =
      this.validateEmail(this.state.email) &&
      this.validatePassword(this.state.password);
    if (!valid) {
      return;
    }

    const body = {
      email: this.state.email,
      password: this.state.password,
    };

    try {
      const response = await global.UF.makeRequest_v2(
        `POST`,
        `/api/auth`,
        body,
        false
      );

      const data = response.body;
      await this.props.onLogin(JSON.stringify(data));
    } catch (err) {
      this.setState({
        email_error_message: "Погрешен емаил",
        password_error_message: "Погрешна лозинка",
      });
    }
  };

  onSuccess = (res) => {
    const data = res.body ? res.body : res;
    this.props.onLogin(JSON.stringify(data));
  };

  onKeyDown = (event) => {
    if (event.keyCode == 13) {
      this.handleLogin();
    }
  };

  render() {
    return (
      <div className="uf_login">
        <div className="uf_login_card">
          <div className="uf_login_logo_section">
            <div className="uf_login_logo">
              <img src={`./assets/images/defektolozi.png`} alt={"logo"} />
            </div>
          </div>
          <div className="uf_login_login">
            <div className="uf_login_text">
              {global.UF.tr("welcome_to_page")}
            </div>
            <div className="uf_login_textfield_wrapper">
              <Nx_Ctrl_TextInput
                onChange={(val) => this.onChange(val, `email`)}
                label={global.UF.tr("email_address")}
                type={"text"}
                error={this.state.email_error_message.length > 0}
                helperText={this.state.email_error_message}
                value={this.state.email}
                onKeyDown={this.onKeyDown}
                inputStyle={{ height: "25px", borderRadius: "10px" }}
                inputLabelStyle={{
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "500",
                }}
              />
            </div>
            <div className="uf_login_textfield_wrapper">
              <Nx_Ctrl_TextInput
                label={global.UF.tr("password")}
                type="password"
                value={this.state.password}
                error={this.state.password_error_message.length > 0}
                helperText={this.state.password_error_message}
                onChange={(val) => this.onChange(val, `password`)}
                onKeyDown={this.onKeyDown}
                inputStyle={{ height: "25px", borderRadius: "10px" }}
                inputLabelStyle={{
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "500",
                }}
              />
            </div>
            <div className={"uf_login_button_container"}>
              <Nx_Button
                label={global.UF.tr("log_in")}
                variant={"contained"}
                onClick={this.handleLogin}
              />
              <Nx_Button
                label={global.UF.tr("forgot_password")}
                variant={"text"}
                onClick={() => this.props.router.navigate(`/forgot-password`)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(LoginScreen);
