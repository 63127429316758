import React from "react";
import "../home.scss";
import NxButton from "../../../../nix_components/ui_components/controls/nx_button";
import { useNavigate } from "react-router-dom";

export default function Card({ title, count, link }) {
  const navigate = useNavigate();
  return (
    <div className="card">
      <div className="card_title">{title}</div>
      <div className="card_content">
        <div className="card_count">{count}</div>
        {/* <div className="card_button">
          <NxButton
            label={"Види повеке"}
            variant={"text"}
            onClick={() => navigate(link)}
          />
        </div> */}
      </div>
    </div>
  );
}
