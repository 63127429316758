import React, { Fragment } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Menu from "../../nix_components/ui_components/components/Menu/Menu.js";
import Grid from "../../nix_components/ui_components/layouts/Grid/Grid";
import withRouter from "../../nix_components/components/withRouter.js";
import Users from "./Users/Users.jsx";
import Regions from "./Regions/Regions.jsx";
import PersonIcon from "@mui/icons-material/Person";
import PublicIcon from "@mui/icons-material/Public";
import LogoutIcon from "@mui/icons-material/Logout";
import Home from "../SharedScreens/Home/Home.jsx";
import Patients from "../SharedScreens/Patients/Patients.jsx";
import AccessibleIcon from "@mui/icons-material/Accessible";
import HomeIcon from "@mui/icons-material/Home";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import CodeSetup from "./CodeSetup/CodeSetup.jsx";
import KeyIcon from "@mui/icons-material/Key";
import MyProfile from "../SharedScreens/MyProfile/MyProfile.jsx";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Subjects from "../SharedScreens/Subjects/Subjects.jsx";
import TransferHistory from "../SharedScreens/TransferHistory/TransferHistory.jsx";
import TransferrWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import ScheduleUps from "../SharedScreens/ScheduleUps/ScheduleUps.jsx";
import AppealBook from "../SharedScreens/AppealBook/AppealBook.jsx";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Reports from "../SharedScreens/Reports/Reports.jsx";
import AdfScannerIcon from "@mui/icons-material/AdfScanner";
import Appeal from "../SharedScreens/Subjects/Appeal.jsx";
import ArticleIcon from "@mui/icons-material/Article";
import SubjectsInProgress from "../SharedScreens/SubjectsInProgress/SubjectsInProgress.jsx";
import Municipalities from "./Municipalities/Municipalities.jsx";
import FirstCheckup from "../SharedScreens/PatientsBook/FirstCheckup.jsx";
import ControlCheckup from "../SharedScreens/PatientsBook/ControlCheckup.jsx";

class SuperAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: true,
      region_id: global.UF.region_id ? global.UF.region_id : "",
      availableRegions: [],
    };

    this.menuItems = [
      {
        title: "Почетна страна",
        level: 1,
        scope: "home",
        url: "/home",
        icon: <HomeIcon />,
        element: () => {
          return <Home {...props} region_id={this.state.region_id} />;
        },
      },
      {
        title: "Корисници",
        level: 1,
        scope: "users",
        url: "/users",
        icon: <PersonIcon />,
        element: () => {
          return <Users {...props} />;
        },
      },
      {
        title: "Пациенти",
        level: 1,
        scope: "patients",
        url: "/patients/:id?",
        icon: <AccessibleIcon />,
        element: () => {
          return <Patients add={true} edit={true} {...props} />;
        },
      },
      {
        title: "Предмет",
        hide: true,
        url: "/subjects/:patient_id/:id/:type/:is_printed?",
        element: () => {
          return <Subjects {...props} />;
        },
      },
      {
        title: "Жалба",
        hide: true,
        url: "/appeal/:patient_id/:id/:type/:is_printed?",
        element: () => {
          return <Appeal {...props} />;
        },
      },
      {
        title: "Извештаи",
        level: 1,
        url: "/reports",
        icon: <AdfScannerIcon />,
        element: () => {
          return <Reports {...props} />;
        },
      },
      {
        title: "Деловодна книга",
        level: 1,
        scope: "patients_book_appeal",
        icon: <MenuBookIcon />,
        children: [
          {
            title: "Прва проценка",
            level: 2,
            scope: "patients_book",
            url: "./patients_book/first_checkup",
            element: () => {
              return <FirstCheckup {...props} />;
            },
          },
          {
            title: "Контролна проценка",
            level: 2,
            scope: "patients_book",
            url: "./patients_book/control_checkup",
            element: () => {
              return <ControlCheckup {...props} />;
            },
          },
          {
            title: "Жалби",
            level: 2,
            scope: "patients_book_appeal",
            url: "./patients_book/appeal",
            element: () => {
              return <AppealBook {...props} />;
            },
          },
        ],
      },
      {
        title: "Деловодна книга на префрлени предмети",
        level: 1,
        scope: "patients_book_appeal",
        icon: <MenuBookIcon />,
        children: [
          {
            title: "Прва проценка",
            level: 2,
            scope: "patients_book",
            url: "./patients_book_transfered/first_checkup",
            element: () => {
              return (
                <FirstCheckup key={"transfered"} {...props} transfered={true} />
              );
            },
          },
          {
            title: "Контролна проценка",
            level: 2,
            scope: "patients_book",
            url: "./patients_book_transfered/control_checkup",
            element: () => {
              return (
                <ControlCheckup
                  key={"transfered"}
                  {...props}
                  transfered={true}
                />
              );
            },
          },
          {
            title: "Жалби",
            level: 2,
            scope: "patients_book_appeal",
            url: "./patients_book_transfered/appeal",
            element: () => {
              return (
                <AppealBook key={"transfered"} {...props} transfered={true} />
              );
            },
          },
        ],
      },
      {
        title: "Закажани проценки",
        level: 1,
        scope: "schedule_ups",
        url: "/schedule_ups",
        icon: <FormatListBulletedIcon />,
        element: () => {
          return <ScheduleUps {...props} region_id={this.state.region_id} />;
        },
      },
      {
        title: "Предмети во тек",
        level: 1,
        url: "/subject_in_progress",
        icon: <ArticleIcon />,
        element: () => {
          return (
            <SubjectsInProgress {...props} region_id={this.state.region_id} />
          );
        },
      },
      {
        title: "Префрлени пациенти во региони",
        level: 1,
        scope: "transfer_history",
        url: "/transfer_history",
        icon: <TransferrWithinAStationIcon />,
        element: () => {
          return <TransferHistory {...props} />;
        },
      },
      {
        title: "Шифрарници",
        level: 1,
        scope: "code_setup",
        icon: <KeyIcon />,
        children: [
          {
            title: global.UF.tr("child_support_measures"),
            url: `./code_setup/child_support_measures`,
            level: 2,
            element: () => {
              return (
                <CodeSetup
                  key={"child_support_measures"}
                  {...props}
                  category={"child_support_measures"}
                />
              );
            },
          },
          {
            title: global.UF.tr("social_protection_measures"),
            url: `./code_setup/social_protection_measures`,
            level: 2,
            element: () => {
              return (
                <CodeSetup
                  key={"social_protection_measures"}
                  {...props}
                  category={"social_protection_measures"}
                />
              );
            },
          },
          {
            title: global.UF.tr("health_care_measures"),
            url: `./code_setup/health_care_measures`,
            level: 2,
            element: () => {
              return (
                <CodeSetup
                  key={"health_care_measures"}
                  {...props}
                  category={"health_care_measures"}
                />
              );
            },
          },
          {
            title: global.UF.tr("diagnoses_according_to_icd10"),
            url: `./code_setup/diagnoses_according_to_icd10`,
            level: 2,
            element: () => {
              return (
                <CodeSetup
                  key={"diagnoses_according_to_icd10"}
                  {...props}
                  category={"diagnoses_according_to_icd10"}
                />
              );
            },
          },
          {
            title: global.UF.tr("codesbody_functions_according_to_ICF"),
            url: `./code_setup/codesbody_functions_according_to_ICF`,
            level: 2,
            element: () => {
              return (
                <CodeSetup
                  key={"codesbody_functions_according_to_ICF"}
                  {...props}
                  category={"codesbody_functions_according_to_ICF"}
                />
              );
            },
          },
          {
            title: global.UF.tr("participation_codes"),
            url: `./code_setup/participation_codes`,
            level: 2,
            element: () => {
              return (
                <CodeSetup
                  {...props}
                  key={"participation_codes"}
                  category={"participation_codes"}
                />
              );
            },
          },
          {
            title: global.UF.tr("disability"),
            url: `./code_setup/disability`,
            level: 2,
            element: () => {
              return (
                <CodeSetup
                  {...props}
                  key={"disability"}
                  category={"disability"}
                />
              );
            },
          },
          {
            title: global.UF.tr("icf_qualifier"),
            url: `./code_setup/icf_qualifier`,
            level: 2,
            element: () => {
              return (
                <CodeSetup
                  {...props}
                  key={"icf_qualifier"}
                  category={"icf_qualifier"}
                />
              );
            },
          },
          {
            title: global.UF.tr("indicator"),
            url: `./code_setup/indicator`,
            level: 2,
            element: () => {
              return (
                <CodeSetup
                  {...props}
                  key={"indicator"}
                  category={"indicator"}
                />
              );
            },
          },
          {
            title: global.UF.tr("codes_facilitator_and_barrier"),
            url: `./code_setup/codes_facilitator_and_barrier`,
            level: 2,
            element: () => {
              return (
                <CodeSetup
                  {...props}
                  key={"codes_facilitator_and_barrier"}
                  category={"codes_facilitator_and_barrier"}
                />
              );
            },
          },
        ],
      },
      {
        title: "Региони",
        level: 1,
        scope: "regions",
        url: "/regions",
        icon: <PublicIcon />,
        element: () => {
          return <Regions {...props} />;
        },
      },
      {
        title: "Општини",
        level: 1,
        scope: "municipalities",
        url: "/municipalities",
        icon: <PublicIcon />,
        element: () => {
          return <Municipalities {...props} />;
        },
      },
      {
        title: "Мој профил",
        level: 1,
        url: "/profile",
        icon: <AccountCircleIcon />,
        element: () => {
          return <MyProfile {...props} />;
        },
      },
      {
        title: "Одјави се",
        level: 1,
        onClick: this.handleLogOut,
        icon: <LogoutIcon />,
      },
    ];
  }

  handleRegions = async () => {
    const { user } = this.props;
    let availableRegions = await global.UF.dataProvider.get_v2(`regions`);
    availableRegions = availableRegions.map((region) => ({
      id: region.id,
      label: region.name,
    }));
    const regionIds = availableRegions.map((region) => region.id);
    if (availableRegions.length) {
      availableRegions.unshift({
        id: regionIds,
        label: "Сите региони",
      });
    }
    global.UF.region_id = regionIds;
    this.setState({ availableRegions, region_id: regionIds });
  };

  handleLogOut = () => {
    localStorage.clear("token");
    localStorage.clear("user");
    window.location.href = "/";
  };

  handleRegionChange = (value) => {
    global.UF.region_id = value;
    this.setState({ region_id: value });
  };

  renderElement = () => {
    const menuItems = this.menuItems.filter((menuItem) => !menuItem.hide);
    this.routes = this.renderRoutes();
    this.routes.push(
      <Route
        path="/*"
        key={new Date().getTime()}
        element={<Navigate to="/home" />}
      />
    );
    return (
      <Grid fullScreen={true} className="uf_layout">
        <Grid className="menu_layout" hidden={!this.state.showMenu}>
          <Menu
            items={menuItems}
            regions={this.state.availableRegions}
            region_id={this.state.region_id}
            handleRegionChange={this.handleRegionChange}
          />
        </Grid>
        <Grid className="content_layout">
          <Routes>{this.routes}</Routes>
        </Grid>
      </Grid>
    );
  };

  renderRoutes = () => {
    if (this.menuItems && this.menuItems.length) {
      return this.menuItems.map((menuItem, index) => {
        if (menuItem.hasOwnProperty("children")) {
          return menuItem.children.map((child, key) => {
            let currentMenuItem = menuItem.children.find(
              (item) => item.url === child.url
            );

            return (
              <Route
                path={`/${currentMenuItem.url.split("/")[1]}/${
                  currentMenuItem.url.split("/")[2]
                }`}
                key={key}
                element={currentMenuItem.element()}
              />
            );
          });
        } else {
          if (menuItem.hasOwnProperty("url") && menuItem.url) {
            let currentMenuItem = this.menuItems.find(
              (item) => item.url === menuItem.url
            );
            const split = currentMenuItem.url.split("/");
            let tempUrl = "";
            if (split.length > 2) {
              split.slice(0, 1);
              tempUrl = split.join("/");
            } else {
              tempUrl = split[1];
            }
            return (
              <Route
                path={`/${tempUrl}`}
                key={index}
                element={currentMenuItem.element()}
              />
            );
          }
        }
      });
    } else return null;
  };

  async componentDidMount() {
    await this.handleRegions();
  }

  render() {
    return <Fragment>{this.renderElement()}</Fragment>;
  }
}

export default withRouter(SuperAdmin);
