import React, { Fragment } from "react";
import Public from "./app/Screens/public";
import UF from "./app/nix_components/utils/UF";
import DataProvider from "./app/nix_components/data/DataProvider";
import Nx_Loader from "./app/nix_components/components/nx_loader";
import Nx_Snackbar from "./app/nix_components/ui_components/alerts/nx_snackbar";
import { usersTypes } from "./app/nix_components/data/DataReferences";
import SuperAdmin from "./app/Screens/SuperAdmin/SuperAdmin";
import RegionalAdmin from "./app/Screens/RegionalAdmin/RegionalAdmin";
import SocialWorker from "./app/Screens/SocialWorker/SocialWorker";
import Doctor from "./app/Screens/Doctor/Doctor";
import { changeLanguage } from "i18next";
import NxRequestLoader from "./app/nix_components/components/NxRequestLoader";

class App extends React.Component {
  constructor(props) {
    super(props);
    global.UF = new UF();
    global.UF.dataProvider = new DataProvider();

    this.state = {
      authenticated:
        global.localStorage.getItem("token") !== undefined &&
        global.localStorage.getItem("token") !== null,
      user: null,
      initializing: true,
      role: null,
    };

    const i18nextLng = localStorage.getItem("i18nextLng");
    if (!i18nextLng) {
      changeLanguage("MK");
      global.UF.language = "MK";
      return;
    }

    if (!["MK", "AL"].includes(i18nextLng)) {
      changeLanguage("MK");
      global.UF.language = "MK";
      return;
    }

    global.UF.language = i18nextLng;
  }

  login = async (loginObj) => {
    const user = JSON.parse(loginObj);

    if (
      !user.hasOwnProperty("access_token") ||
      !user.hasOwnProperty("email") ||
      !user.hasOwnProperty("id")
    ) {
      this.setState({ user: null, authenticated: false });
      return;
    }

    try {
      const users = await global.UF.dataProvider.get_v2(`users`, {
        id: user.id,
        status: "active",
      });

      if (!users.length) {
        this.setState({
          user: null,
          authenticated: false,
          initializing: false,
          role: null,
        });
        return;
      }

      const logged_user = users[0];
      localStorage.setItem("user", loginObj);
      localStorage.setItem("token", user.access_token);
      this.setState({
        authenticated: true,
        user: logged_user,
        initializing: false,
        role: usersTypes[logged_user.user_type_id],
      });
    } catch (err) {
      global.UF.handleError(err);
      this.setState({
        user: null,
        authenticated: false,
        initializing: false,
        role: null,
      });
    }
  };

  logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    window.location.href = "/";
  };

  setUser = async () => {
    const user =
      localStorage.getItem("user") != null &&
      typeof JSON.parse(localStorage.getItem("user")) === "object"
        ? JSON.parse(localStorage.getItem("user"))
        : null;

    if (!user) {
      this.setState({
        authenticated: false,
        user: null,
        initializing: false,
        role: null,
      });
      return;
    }

    if (
      !user.hasOwnProperty("access_token") ||
      !user.hasOwnProperty("email") ||
      !user.hasOwnProperty("id")
    ) {
      this.setState({
        user: null,
        authenticated: false,
        initializing: false,
        role: null,
      });
      return;
    }
    try {
      const users = await global.UF.dataProvider.get_v2(`users`, {
        id: user.id,
        status: "active",
      });

      if (!users.length) {
        this.setState({
          user: null,
          authenticated: false,
          initializing: false,
          role: null,
        });
        return;
      }

      const logged_user = users[0];
      this.setState({
        authenticated: true,
        user: logged_user,
        initializing: false,
        role: usersTypes[logged_user.user_type_id],
      });
    } catch (err) {
      global.UF.handleError(err);
      this.setState({
        user: null,
        authenticated: false,
        initializing: false,
        role: null,
      });
    }
  };

  componentDidMount = async () => {
    const link = document.querySelector("link[rel*='icon']");
    if (link) {
      link.href = `../../assets/images/defektolozi.png`;
    }
    try {
      document.title = "Здравствен дом";
      global.UF.resetApp = () => {
        this.forceUpdate();
      };
      await this.setUser();
    } catch (err) {
      global.UF.handleError(err);
    }
  };

  renderSecureApp = (role) => {
    if (!role) {
      global.UF.handleError(
        "Молим вас контактирајте администратора, ваш корисник нема ролу!"
      );
      this.logout();
      return;
    }

    switch (role) {
      case usersTypes[1]:
        return (
          <SuperAdmin
            key={"app-" + global.UF.language}
            user={this.state.user}
          />
        );
      case usersTypes[2]:
        return <RegionalAdmin user={this.state.user} />;
      case usersTypes[3]:
        return <SocialWorker user={this.state.user} />;
      case usersTypes[4]:
        return <Doctor user={this.state.user} />;
      default:
        return null;
    }
  };

  renderApp = () => {
    if (this.state.authenticated) {
      return this.renderSecureApp(this.state.role);
    } else {
      return <Public onLogin={this.login} />;
    }
  };

  render() {
    if (this.state.initializing) {
      return <Nx_Loader show={true} />;
    } else {
      return (
        <Fragment>
          <Nx_Snackbar />
          <NxRequestLoader />
          <Nx_Loader />
          {this.renderApp()}
        </Fragment>
      );
    }
  }
}

export default App;
