import React from "react";
import UF_Content from "../../../nix_components/components/UF_Content";
import NxIconButton from "../../../nix_components/ui_components/controls/nx_icon_button";
import PasswordIcon from "@mui/icons-material/Password";
import SetPasswordModal from "../../../components/modals/SetPasswordModal";
import Grid from "../../../nix_components/ui_components/layouts/Grid/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton, Tooltip } from "@mui/material";
import { usersTypes } from "../../../nix_components/data/DataReferences";
import EditIcon from "@mui/icons-material/Edit";

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordItem: null,
      menuEl: null,
      role: null,
      regions: null,
    };
    global.UF.dataProvider.datastructure["users"].filters = {
      custom_filter: "email != 'temp@gmail.com'",
    };
    // global.UF.dataProvider.datastructure["users"].filters = {
    //   user_type_id: [3, 4],
    // };

    this.handleRegions();
  }

  buttons = {
    setPassword: (params) => {
      const passwordItem = params.row;
      return (
        [3, 4].includes(passwordItem.user_type_id) && (
          <NxIconButton
            tooltip={"Промени лозинка"}
            color="default"
            onClick={() => this.setState({ passwordItem })}
          >
            <PasswordIcon />
          </NxIconButton>
        )
      );
    },
    edit: (params) => {
      const dataItem = params.row;
      return (
        [3, 4].includes(dataItem.user_type_id) && (
          <NxIconButton
            size={"small"}
            tooltip="Промени"
            onClick={() => this.onEdit(dataItem)}
            key={new Date().getTime()}
          >
            <EditIcon />
          </NxIconButton>
        )
      );
    },
  };

  formConfigAdd = {
    rows: [
      ["first_name", "last_name"],
      ["password", "confirm_password"],
      ["email", "phone"],
      ["region_id", "user_type_id"],
      ["status", {}],
    ],
  };

  formConfigEdit = {
    rows: [
      ["first_name", "last_name"],
      ["email", "phone"],
      ["region_id", "user_type_id"],
      ["status", {}],
    ],
  };

  gridProps = {
    fields: [
      "first_name",
      "region_id",
      "user_type_id",
      "specialist_type",
      "doctor_type",
      "status",
    ],
  };

  handleRegions = () => {
    global.UF.dataProvider.referenceProvider.get(`regions`, (regions) => {
      const user_regions = JSON.parse(this.props.user.region_id);
      const filtered_regions = regions.filter((region) =>
        user_regions.includes(JSON.stringify(region.id))
      );
      this.setState({ regions: filtered_regions });
    });
  };

  checkIfUserIsKt = (user) => {
    const region_id =
      typeof user.region_id === "string"
        ? JSON.parse(user.region_id)
        : user.region_id;

    if (region_id) {
      if (Array.isArray(region_id)) {
        region_id.forEach((id) => {
          const label =
            global.UF.dataProvider.referenceProvider.getReferenceLabel(
              `regions`,
              id
            );
          if (label == "Координативно тело") {
            user.is_kt = 1;
          }
        });
      } else {
        const label =
          global.UF.dataProvider.referenceProvider.getReferenceLabel(
            `regions`,
            region_id
          );
        if (label == "Координативно тело") {
          user.is_kt = 1;
        }
      }
    }
  };

  onSave = (dataItem, callback) => {
    this.checkIfUserIsKt(dataItem);
    if (dataItem.isNew) {
      dataItem.password = global.UF.cryptPassword(dataItem.password);
      dataItem.confirm_password = dataItem.password;
    }

    dataItem.Save((response) => {
      if (response.hasOwnProperty("error")) {
        global.UF.setAlertVisibility(true, response.error, "error");
        return;
      }
      if (callback) {
        callback();
      }
    });
  };

  setPassword = async (password, user) => {
    try {
      await global.UF.makeRequest_v2(
        `POST`,
        `/api/set-password`,
        { id: user.id, password: password },
        true
      );
      this.setState({ passwordItem: null });
    } catch (err) {
      console.error(err);
      global.UF.setAlertVisibility(true, err.toString(), "error");
    }
  };

  renderSetPasswordModal = () => {
    return (
      <SetPasswordModal
        open={this.state.passwordItem !== null}
        onClose={() => this.setState({ passwordItem: null })}
        onSetPassword={async (password) =>
          await this.setPassword(password, this.state.passwordItem)
        }
      />
    );
  };

  handleOpenMenu = (e) => {
    this.setState({ menuEl: e.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ menuEl: null });
  };

  handleSettingFormBasedOnRole = (role) => {
    let rows = [];
    switch (role) {
      case usersTypes[3]:
        global.UF.dataProvider.datastructure["users"].ignoreValidation.push(
          "doctor_type"
        );
        global.UF.dataProvider.datastructure["users"].ignoreValidation =
          global.UF.dataProvider.datastructure["users"].ignoreValidation.filter(
            (validation) =>
              validation != "region_id" || validation != "specialist_type"
          );
        rows = [
          ["first_name", "last_name"],
          ["password", "confirm_password"],
          ["email", "phone"],
          [
            { field: "region_id", data: this.state.regions, reference: null },
            "specialist_type",
          ],
          ["status", {}],
        ];
        break;
      case usersTypes[4]:
        global.UF.dataProvider.datastructure["users"].ignoreValidation.push(
          "specialist_type"
        );
        global.UF.dataProvider.datastructure["users"].ignoreValidation =
          global.UF.dataProvider.datastructure["users"].ignoreValidation.filter(
            (validation) =>
              validation != "region_id" || validation != "doctor_type"
          );
        rows = [
          ["first_name", "last_name"],
          ["password", "confirm_password"],
          ["doctor_type", {}],
          ["email", "phone"],
          [
            { field: "region_id", data: this.state.regions, reference: null },
            "status",
          ],
        ];
        break;
      default:
        rows = [];
        break;
    }
    this.formConfigAdd.rows = rows;
    this.formConfigEdit.rows = [...rows.slice(0, 1), ...rows.slice(2)];
  };

  handleMenuItemClick = (role, addItem) => {
    this.handleSettingFormBasedOnRole(role);
    this.setState({ role }, () => {
      if (addItem) {
        addItem();
      }
    });
  };

  handleRegionChange = (dataItem) => {
    const regions = dataItem["region_id"];
    if (!Array.isArray(regions)) {
      return;
    }

    if (!regions.length) {
      dataItem["region_id"] = null;
      return;
    }

    dataItem["region_id"] = regions;
  };

  renderAddButton = (addItem) => {
    //argument addItem is func
    return (
      <>
        <Grid className="uf_content_wrapper_for_tools">
          <Tooltip title={`Нов корисник`}>
            <IconButton size="small" onClick={(e) => this.handleOpenMenu(e)}>
              Нов корисник
            </IconButton>
          </Tooltip>
        </Grid>
        <Menu
          id="basic-menu"
          anchorEl={this.state.menuEl}
          open={Boolean(this.state.menuEl)}
          onClose={this.handleCloseMenu}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {Object.keys(usersTypes).map((type, key) => {
            if (
              usersTypes[type] === "super_admin" ||
              usersTypes[type] === "regional_admin"
            ) {
              return null;
            }
            return (
              <MenuItem
                key={key}
                onClick={() =>
                  this.handleMenuItemClick(usersTypes[type], addItem)
                }
              >
                {global.UF.tr(usersTypes[type])}
              </MenuItem>
            );
          })}
        </Menu>
      </>
    );
  };

  beforeAddNew = (dataItem, callback) => {
    global.UF.dataProvider.datastructure.users.ignoreValidation =
      global.UF.dataProvider.datastructure.users.ignoreValidation.filter(
        (item) => item !== "password" && item !== "confirm_password"
      );
    const key = Object.keys(usersTypes).find(
      (key) => usersTypes[key] === this.state.role
    );
    dataItem.user_type_id = key;
    if (callback) {
      callback(dataItem);
    }
  };

  onEdit = (editItem) => {
    const role = usersTypes[editItem.user_type_id];
    global.UF.dataProvider.datastructure.users.ignoreValidation.push(
      "password",
      "confirm_password"
    );
    this.handleSettingFormBasedOnRole(role);
    this.setState({ role });
    return editItem;
  };

  render() {
    return (
      <>
        {this.state.passwordItem && this.renderSetPasswordModal()}
        <UF_Content
          table="users"
          title="Преглед и управување со кориснички профили"
          formTitle="Корисник"
          gridProps={this.gridProps}
          buttons={this.buttons}
          edit={false}
          onSave={this.onSave}
          onEdit={this.onEdit}
          addButton={this.renderAddButton}
          beforeAddNew={this.beforeAddNew}
          formConfigAdd={this.formConfigAdd}
          formConfig={this.formConfigEdit}
          region_id={this.props.region_id}
          {...this.props}
        />
      </>
    );
  }
}

export default Users;
