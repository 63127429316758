import React from "react";
import { Chip, FormControl, TextField } from "@mui/material";
import "./controls.scss";

class Nx_Ctrl_MultiNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [],
      currValue: "",
    };
  }

  componentDidMount() {
    this.state.values =
      typeof this.state.values == "string"
        ? JSON.parse(this.state.values)
        : this.state.values;
    this.state.values =
      this.props.dataItem[this.props.fieldConfig.field] !== undefined &&
      this.props.dataItem[this.props.fieldConfig.field] !== null &&
      Array.isArray(this.props.dataItem[this.props.fieldConfig.field])
        ? this.props.dataItem[this.props.fieldConfig.field]
        : typeof this.props.dataItem[this.props.fieldConfig.field] == "string"
        ? JSON.parse(this.props.dataItem[this.props.fieldConfig.field])
        : [];
    this.forceUpdate();
  }

  handleKeyUp = async (e) => {
    if (e.target.value) {
      if (e.keyCode != 13) {
        return;
      }
      const tempArr = [...this.state.values, e.target.value];
      if (this.props.fieldConfig.onKeyUp) {
        await this.props.fieldConfig.onKeyUp(this.props.dataItem, tempArr);
        this.setState({ currValue: "", values: tempArr });
        return;
      }
      this.props.onChange(tempArr);
      this.setState({ currValue: "", values: tempArr });
    }
  };

  handleChange = (e) => {
    this.setState({ currValue: e.target.value });
  };

  handleDelete = (index) => {
    const tempArr = [...this.state.values];
    tempArr.splice(index, 1);
    this.props.onChange(tempArr.length ? tempArr : []);
    this.setState({ values: [...tempArr] });
  };

  render() {
    return (
      <FormControl sx={{ width: "100%" }}>
        <TextField
          value={this.state.currValue}
          label={
            this.props.fieldConfig.label
              ? this.props.fieldConfig.label
              : this.props.fieldConfig.field
          }
          type={"text"}
          helperText={
            this.props.helperText && this.props.helperText.length
              ? this.props.helperText
              : ""
          }
          error={this.props.error}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyUp}
          className={`${
            this.props.error ? "nx_error" : ""
          } nx_multinumber_wrapper nx_multinumber_input`}
        />
        <div className={"container nx_multinumber"}>
          {this.state.values !== null &&
          Array.isArray(this.state.values) &&
          this.state.values.length
            ? this.state.values.map((item, index) => (
                <Chip
                  size="medium"
                  key={index}
                  onDelete={() => this.handleDelete(index)}
                  label={item}
                />
              ))
            : null}
        </div>
        {this.props.helperText && this.props.helperText.length ? null : <div />}
      </FormControl>
    );
  }
}

export default Nx_Ctrl_MultiNumber;
