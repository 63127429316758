import React, { useState } from "react";
import NxGridView from "../../../nix_components/components/NxGridView";
import Grid from "../../../nix_components/ui_components/layouts/Grid/Grid";
import UF_Content from "../../../nix_components/components/UF_Content";
import { useNavigate } from "react-router-dom";
import { usersTypes } from "../../../nix_components/data/DataReferences";
import TransferPatientRegion from "./components/modals/TransferPatientRegion";
import AddSubjectModal from "./components/modals/AddSubjectModal";

export default function Patient({ id, dataItem, user }) {
  const navigate = useNavigate();
  const [buttons, setButtons] = useState({});
  const [tools, setTools] = useState({});
  const [patient, setPatient] = useState(dataItem);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showAddSubjectModal, setShowAddSubjectModal] = useState(false);

  global.UF.dataProvider.datastructure["subjects"].filters = {
    patient_id: id,
  };

  const viewConfig = {
    rows: [
      ["id_number", "gender"],
      ["first_name", "fathers_name", "last_name"],
      ["date_of_birth", "address", "city"],
      ["community", "living_place", "zip_code"],
      ["region_id", "birth_place"],
      ["contact_phone", "contact_email"],
      ["contact_name", "contact_relationship"],
    ],
  };

  const gridProps = {
    fields: [
      "delovoden_broj",
      "subject_type",
      "created_by",
      "status",
      "created_at",
    ],
  };

  const formConfig = {
    rows: [["patient_id"]],
  };

  const handleFormViewButtons = () => {
    const valid = ["super_admin", "regional_admin"].includes(
      usersTypes[user.user_type_id]
    );

    if (!valid) {
      return {};
    }

    return [
      {
        label: "Пренос во друг регион",
        variant: "text",
        onClick: () => setShowTransferModal(true),
      },
    ];
  };

  const handleTransferModalClose = async () => {
    const patients = await global.UF.dataProvider.get_v2(`patients`, {
      id: id,
    });
    setPatient(patients[0]);
    setShowTransferModal(false);
  };

  const renderTransferModal = () => {
    return (
      <TransferPatientRegion
        open={showTransferModal}
        handleClose={handleTransferModalClose}
        current_region={global.UF.dataProvider.referenceProvider.getReferenceLabel(
          `regions`,
          patient.region_id
        )}
        patient_id={id}
        user={user}
      />
    );
  };

  const renderAddSubjectModal = () => {
    return (
      <AddSubjectModal
        open={showAddSubjectModal}
        handleClose={() => setShowAddSubjectModal(false)}
        patient_id={id}
        user={user}
        region_id={patient?.region_id}
      />
    );
  };

  const handleSubjectRowClick = (row) => {
    const subject_id = row.id;
    const subject_type = row.row.subject_type;
    if (subject_type === "appeal") {
      navigate(`/appeal/${id}/${subject_id}/${subject_type}`);
      return;
    }
    navigate(`/subjects/${id}/${subject_id}/${subject_type}`);
  };

  const handleAddingNewSubject = async () => {
    const subjects = await global.UF.dataProvider.get_v2(`subjects`, {
      status: "in_progress",
      patient_id: id,
    });

    if (!subjects.length) {
      setShowAddSubjectModal(true);
      return;
    }

    global.UF.setAlertVisibility(
      true,
      "Веќе постои еден предмет во изработка за овој пациент!",
      "warning"
    );
  };

  return (
    <Grid style={{ alignItems: "center" }}>
      {showTransferModal && renderTransferModal()}
      {showAddSubjectModal && renderAddSubjectModal()}
      <Grid
        className="patient_wrapper"
        style={{
          gridTemplateColumns: "40% 55%",
          justifyContent: "space-evenly",
          height: "95%",
        }}
      >
        <NxGridView
          key={patient?.region_id}
          dataItem={patient}
          table={"patients"}
          title={"Пациент"}
          viewConfig={viewConfig}
          buttons={handleFormViewButtons()}
        />

        <UF_Content
          table="subjects"
          title="Преглед и управување со предмети"
          formTitle="Предмет"
          gridProps={gridProps}
          buttons={buttons}
          tools={tools}
          edit={true}
          onAdd={handleAddingNewSubject}
          onRowClick={handleSubjectRowClick}
          formConfig={formConfig ? formConfig : {}}
          add={true}
        />
      </Grid>
    </Grid>
  );
}
