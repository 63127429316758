import React from "react";
import UF_Content from "../../../nix_components/components/UF_Content";

export default function SubjectsInProgress({ user, region_id, router, props }) {
  global.UF.dataProvider.datastructure[
    "view_all_subjects_with_region_id"
  ].filters = {
    status: "in_progress",
    region_id: region_id,
  };

  const gridProps = {
    fields: [
      "delovoden_broj",
      "subject_type",
      "created_by",
      "status",
      "created_at",
    ],
  };

  const handleRowClick = (row) => {
    const subject = row.row;
    router.navigate(`/patients/${subject.patient_id}`);
  };

  return (
    <UF_Content
      table="view_all_subjects_with_region_id"
      title="Преглед и управување со активни предмети"
      gridProps={gridProps}
      onRowClick={handleRowClick}
      buttons={{}}
      edit={false}
      add={false}
      {...props}
      user={user}
    />
  );
}
