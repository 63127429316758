import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function SubjectSelect({
  reference,
  multiple,
  className,
  subject_details,
  field,
  read_only,
}) {
  const handleValue = () => {
    let value = subject_details[field];
    if (multiple) {
      if (typeof value === "string" && value.length) {
        value = JSON.parse(value);
      }
      return Array.isArray(value) ? value : [];
    } else {
      return value ? value : "";
    }
  };

  const [items, setItems] = React.useState([]);
  const [ref, setRef] = React.useState("");
  const [value, setValue] = React.useState(handleValue());

  useEffect(() => {
    if (reference) {
      const split = reference.split(".");
      let ref = split[1];
      let splitWithCategory = "";
      let filters = {};
      if (ref.includes(":")) {
        splitWithCategory = ref.split(":");
        if (splitWithCategory[0] === "users") {
          const split = splitWithCategory[1].split("=")[1];
          const user_types = split.split(",");
          filters = {
            user_type_id: 1,
          };
        }
        if (splitWithCategory[0] === "code_setup") {
          filters = {
            category: splitWithCategory[1],
          };
        }
        ref = splitWithCategory[0];
      }

      global.UF.dataProvider.referenceProvider.get(
        ref,
        (retrievedItems) => {
          retrievedItems = retrievedItems.sort((a, b) =>
            a.label.localeCompare(b.label)
          );
          retrievedItems = retrievedItems.filter(
            (item) => item.label && item.label.length
          );
          setItems(retrievedItems);
          setRef(ref);
        },
        false,
        filters
      );
    }
  }, [reference]);

  const onChange = (e) => {
    const value = e.target.value;
    subject_details[field] = value;
    setValue(value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth className={"subject_select"}>
        <Select
          readOnly={read_only}
          value={value}
          onChange={(e) => onChange(e, ref, multiple)}
          multiple={multiple}
          renderValue={(selected) => {
            if (multiple) {
              const value = items
                .filter((item) => selected.includes(item.id))
                .map((item) => item.label);
              return value.join(",\n");
            }
            const selectedItem = items.find((item) => item.id == selected);
            return selectedItem?.label;
          }}
        >
          {items?.map((item, key) => {
            return (
              <MenuItem key={key} value={item.id}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
