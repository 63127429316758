import React, { useEffect } from "react";
import PropTypes from "prop-types";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { FormHelperText } from "@mui/material";
import "./controls.scss";

const Nx_Ctrl_Select = ({
  dataItem,
  fieldConfig,
  onChange,
  data,
  value,
  disabled,
  className,
  error,
  helperText,
  read_only,
}) => {
  let [items, setItems] = React.useState([]);
  if (
    fieldConfig &&
    fieldConfig.hasOwnProperty("reference_groupby") &&
    dataItem[fieldConfig.reference_groupby]
  ) {
    items = items.filter((item) => {
      if (item.hasOwnProperty("group")) {
        return item.group == dataItem[fieldConfig.reference_groupby];
      } else {
        return true;
      }
    });
  }

  useEffect(() => {
    if (fieldConfig.reference) {
      global.UF.dataProvider.referenceProvider.get(
        fieldConfig.reference,
        (retrievedItems) => {
          retrievedItems = retrievedItems.sort((a, b) =>
            a.label.localeCompare(b.label)
          );
          retrievedItems = retrievedItems.filter(
            (item) => item.label && item.label.length
          );
          setItems(retrievedItems);
        }
      );
    }
  }, [fieldConfig?.reference]);

  useEffect(() => {
    if (data !== null) {
      if (Array.isArray(data)) {
        setItems(data);
      }
      if (typeof data === "object") {
        setItems(data);
      }
    }
  }, [data]);

  const onValueChange = (e) => {
    if (dataItem) {
      dataItem[fieldConfig.name] = e.target.value;
    }
    if (onChange) {
      if (dataItem) {
        onChange(dataItem[fieldConfig?.name]);
      } else {
        onChange(e.target.value);
      }
    }
  };

  const renderMenuItems = () => {
    return fieldConfig.reference
      ? items && items.length
        ? items.map((item, i) => (
            <MenuItem key={i} value={`${item.id}`}>
              {item.label}
            </MenuItem>
          ))
        : null
      : Array.isArray(items)
      ? items && items.length
        ? items.map((item, i) => {
            return (
              <MenuItem
                key={i}
                value={item.hasOwnProperty("id") ? item.id : item}
                disabled={item.disabled}
              >
                {item.hasOwnProperty("label") ? item.label : item}
              </MenuItem>
            );
          })
        : null
      : items
      ? Object.keys(items).map((key, i) => {
          return (
            <MenuItem key={i} value={key}>
              {items[key]}
            </MenuItem>
          );
        })
      : null;
  };

  return (
    <>
      <FormControl
        sx={
          fieldConfig && fieldConfig.width
            ? { width: fieldConfig.width }
            : { width: "100%" }
        }
        className={`nx_textfield${
          read_only ? "_read_only" : ""
        } nx_selectfield ${error ? "nx_error" : ""} ${
          className ? className : ""
        }`}
      >
        <InputLabel>
          {fieldConfig && fieldConfig.label
            ? fieldConfig.label
            : fieldConfig?.name}
        </InputLabel>
        <Select
          variant={
            fieldConfig && fieldConfig.variant
              ? fieldConfig.variant
              : "outlined"
          }
          disabled={disabled || fieldConfig.disabled}
          size={fieldConfig && fieldConfig.size ? fieldConfig.size : "small"}
          label={
            fieldConfig && fieldConfig.label
              ? fieldConfig.label
              : fieldConfig?.name
          }
          multiple={
            fieldConfig && fieldConfig.multiple ? fieldConfig.multiple : false
          }
          inputProps={{ readOnly: read_only }}
          value={
            fieldConfig && fieldConfig.multiple
              ? Array.isArray(value)
                ? value
                : value != undefined && value != null && value != "null"
                ? JSON.parse(value)
                : []
              : value !== undefined && value !== null
              ? typeof value == "string"
                ? value.includes('"', 0)
                  ? JSON.parse(value)
                  : value
                : value
              : ""
          }
          error={error}
          onChange={onValueChange}
        >
          {renderMenuItems()}
        </Select>
        <span className="nx_select_helpertext">
          <FormHelperText>{helperText}</FormHelperText>
        </span>
      </FormControl>
    </>
  );
};

Nx_Ctrl_Select.propTypes = {
  dataItem: PropTypes.object,
  fieldConfig: PropTypes.object,
};

export default Nx_Ctrl_Select;
