import React from "react";
import UF_Content from "../../../nix_components/components/UF_Content";
import withRouter from "../../../nix_components/components/withRouter";
import UF_DateTimePicker from "../../../nix_components/ui_components/controls/nx_ctrl_datetime";
import data_types from "../../../nix_components/data/data_types";
import Grid from "../../../nix_components/ui_components/layouts/Grid/Grid";
import moment from "moment";

class ScheduleUps extends React.Component {
  constructor(props) {
    super(props);
    const date = new Date();
    const date_from = new Date();
    const date_to = date.setMonth(date.getMonth() + 1);
    this.state = {
      date_from: moment(date_from).format("YYYY-MM-DD"),
      date_to: moment(date_to).format("YYYY-MM-DD"),
    };
    global.UF.dataProvider.datastructure["view_schedule_ups"].filters = {
      custom_filter: `date_of_reassessment between '${this.state.date_from}' and '${this.state.date_to}'`,
    };
  }

  fetchData = () => {};

  buttons = {};

  formConfig = {
    rows: [],
  };

  gridProps = {
    fields: [
      "date_of_reassessment",
      "embg",
      "first_name",
      "last_name",
      "number",
      "region_id",
    ],
  };

  handleRowClick = (row) => {
    const dataItem = row.row;
    const { patient_id } = dataItem;
    this.props.router.navigate(`/patients/${patient_id}`);
  };

  renderDatePicker = () => {
    return (
      <Grid
        style={{
          gap: "20px",
          gridTemplateColumns: "repeat(2,1fr)",
          maxWidth: "400px",
          marginTop: "5px",
        }}
      >
        <UF_DateTimePicker
          fieldConfig={{ label: "Од", datatype: data_types.date }}
          onChange={this.handleFromChange}
          value={this.state.date_from}
        />
        <UF_DateTimePicker
          fieldConfig={{ label: "До", datatype: data_types.date }}
          onChange={this.handleToChange}
          value={this.state.date_to}
        />
      </Grid>
    );
  };

  handleFromChange = (date_from) => {
    setTimeout(() => {
      this.setState({ date_from }, () => {
        global.UF.dataProvider.datastructure["view_schedule_ups"].filters = {
          custom_filter: `date_of_reassessment between '${this.state.date_from}' and '${this.state.date_to}'`,
        };
        this.fetchData();
      });
    }, 500);
  };

  handleToChange = (date_to) => {
    setTimeout(() => {
      this.setState({ date_to }, () => {
        global.UF.dataProvider.datastructure["view_schedule_ups"].filters = {
          custom_filter: `date_of_reassessment between '${this.state.date_from}' and '${this.state.date_to}'`,
        };
        this.fetchData();
      });
    }, 500);
  };

  getFetchData = (fetchData) => {
    this.fetchData = fetchData;
  };

  render() {
    return (
      <>
        <UF_Content
          table="view_schedule_ups"
          title="Преглед на закажани проценки"
          gridProps={this.gridProps}
          buttons={this.buttons}
          delete={false}
          edit={false}
          formConfig={this.formConfig}
          fetchData={this.getFetchData}
          add={false}
          onRowClick={this.handleRowClick}
          renderCustomFilters={this.renderDatePicker()}
          {...this.props}
        />
      </>
    );
  }
}

export default withRouter(ScheduleUps);
