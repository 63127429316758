import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export default function LineChart({ data }) {
  return (
    <div
      style={{
        display: "block",
        width: "100%",
        maxWidth: "760px",
        height: "500px",
        position: "relative",
      }}
    >
      <Line
        data={data}
        options={{
          maintainAspectRatio: false,
          aspectRatio: 1,
          responsive: true,
          scales: {
            y: {
              min: 0,
            },
          },
        }}
      />
    </div>
  );
}
