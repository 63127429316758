import React, { useEffect, useState } from "react";
import Nx_Button from "../../../../nix_components/ui_components/controls/nx_button";
import { usersTypes } from "../../../../nix_components/data/DataReferences";

export default function SubjectApproveButton({
  field,
  user,
  subject_id,
  updateSubject,
  className,
  subject_type,
  patient,
  subject,
}) {
  const [disabled, setDisabled] = useState(false);
  const [current_user_type, setCurrentUserType] = useState("");

  const handleDisablingButton = async () => {
    const userType = usersTypes[user.user_type_id];

    const type = field.replace("approved_", "");

    if (subject[`${type}_id`]) {
      setDisabled(true);
      return;
    }

    if (subject_type === "appeal") {
      if (userType === "doctor" || userType === "specialist") {
        setDisabled(true);
      } else {
        if (userType === "super_admin") {
          setDisabled(false);
        } else {
          const region_id =
            typeof user.region_id === "string"
              ? JSON.parse(user.region_id)
              : user.region_id;

          const regions = await global.UF.dataProvider.get_v2(`regions`, {
            id: region_id,
          });

          const hasKT = regions.findIndex((region) => region.code == "КТ") > -1;

          if (!hasKT) {
            setDisabled(true);
            return;
          }

          const type = field.replace("approved_", "");
          if (type === "doctor" && user.doctor_type) {
            setDisabled(false);
            setCurrentUserType("doctor");
            return;
          }

          const user_specialist_type = user.specialist_type;

          if (user_specialist_type === type) {
            setDisabled(false);
            setCurrentUserType(user_specialist_type);
          } else {
            setDisabled(true);
          }
        }
      }
    } else {
      if (userType === "regional_admin") {
        const patient_region_id = patient.region_id;
        const region_id =
          typeof user.region_id === "string"
            ? JSON.parse(user.region_id)
            : user.region_id;
        if (!region_id.includes(String(patient_region_id))) {
          setDisabled(true);
          return;
        }
        const type = field.replace("approved_", "");
        if (type === "doctor" && user.doctor_type) {
          setDisabled(false);
          setCurrentUserType("doctor");
          return;
        }

        const user_specialist_type = user.specialist_type;

        if (user_specialist_type === type) {
          setDisabled(false);
          setCurrentUserType(user_specialist_type);
        } else {
          setDisabled(true);
        }

        return;
      }
      if (userType === "super_admin") {
        setDisabled(true);
        return;
      }
      const type = field.replace("approved_", "");

      if (userType === "doctor") {
        if (type === "doctor") {
          setDisabled(false);
          setCurrentUserType("doctor");
        } else {
          setDisabled(true);
        }
        return;
      }

      const user_specialist_type = user.specialist_type;

      if (user_specialist_type === type) {
        setDisabled(false);
        setCurrentUserType(user_specialist_type);
      } else {
        setDisabled(true);
      }
    }
  };

  useEffect(() => {
    handleDisablingButton();
  }, []);

  const handleOnClick = async () => {
    const subjects = await global.UF.dataProvider.get_v2(`subjects`, {
      id: subject_id,
    });

    if (!subjects.length) {
      global.UF.setAlertVisibility(
        true,
        "Не постоји предмет са тим ИД",
        "error"
      );
      return;
    }

    let user_type = current_user_type;

    if (!user_type || !user_type.length) {
      const type = field.replace("approved_", "");
      user_type = type;
    }

    const subject = subjects[0];
    subject[`${user_type}_id`] = user.id;
    subject[`${user_type}_signed_at`] = new Date();

    let approved = true;

    const validate_fields = [
      "social_worker_id",
      "psychologist_id",
      "special_educator_and_rehabilitator_id",
      "doctor_id",
    ];

    validate_fields.forEach((field) => {
      approved = approved && subject[field];
    });

    if (approved) {
      subject.status = "approved";
    }

    subject.Save(() => {
      if (updateSubject) {
        setDisabled(true);
        updateSubject();
      }
    });
  };

  return (
    <Nx_Button
      className={className ? className : ""}
      label="Потпиши"
      disabled={disabled}
      variant={"text"}
      onClick={async () => await handleOnClick()}
    />
  );
}
