import React from "react";
import UF_Content from "../../../nix_components/components/UF_Content";

class TransferHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  buttons = {};

  formConfig = {
    rows: [],
  };

  gridProps = {
    fields: [
      "patient_fullname",
      "old_region_id",
      "new_region_id",
      "transfer_reason",
      "created_at",
    ],
  };

  render() {
    return (
      <>
        <UF_Content
          table="transfer_history"
          title="Преглед на историја на префрлени пациенти по региони"
          formTitle="Префрлање региона"
          gridProps={this.gridProps}
          buttons={this.buttons}
          delete={false}
          edit={false}
          formConfig={this.formConfig}
          add={false}
          {...this.props}
        />
      </>
    );
  }
}

export default TransferHistory;
