import React from "react";
import UF_Content from "../../../nix_components/components/UF_Content";

class Regions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  buttons = {};

  formConfig = {
    rows: [
      ["name", "code"],
      ["address", "email"],
      ["phone", "status"],
    ],
  };

  gridProps = {
    fields: ["name", "email", "phone", "status"],
  };

  render() {
    return (
      <>
        <UF_Content
          table="regions"
          title="Листа на региони на територија на Република С. Македонија"
          formTitle="Регион"
          gridProps={this.gridProps}
          buttons={this.buttons}
          edit={true}
          formConfig={this.formConfig}
          add={true}
          {...this.props}
        />
      </>
    );
  }
}

export default Regions;
