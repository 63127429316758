import React, { Fragment, useEffect, useState } from "react";
import Grid from "../ui_components/layouts/Grid/Grid";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import "./nx_grid.scss";
import NxConfirm from "../ui_components/alerts/nx_confirm";

const Nx_Grid_Popover = (props) => {
  const [buttons, setButtons] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (props.buttons && typeof props.buttons === "object") {
      buttons.delete = () => {
        return (
          <Grid style={{ justifyContent: "center", alignItems: "center" }}>
            <IconButton onClick={() => setShow(true)} size={"large"}>
              <DeleteIcon fontSize={"large"} sx={{ color: "#2D5DFCB2" }} />
            </IconButton>
          </Grid>
        );
      };
      if (props.rows.length > 1 && buttons.hasOwnProperty("edit")) {
        delete buttons.edit;
      }
      setButtons({ ...props.buttons, ...buttons });
    }
  }, [props.buttons, props.rows]);

  const deleteItems = () => {
    if (props.rows && props.rows.length) {
      const table = props.rows[0].table_structure.TableName;
      if (table === "registration_attendee") {
        props.rows.forEach(async (dataItem, index) => {
          try {
            const attendee = await global.UF.dataProvider.get_v2(`attendees`, {
              id: dataItem.id,
            });
            if (attendee && attendee.length) {
              const currentAttendee = attendee[0];
              currentAttendee.Delete(() => {
                if (index + 1 === props.rows.length) {
                  if (props.hasOwnProperty("fetchData")) {
                    props.fetchData();
                  }
                }
              });
            }
          } catch (err) {
            console.error(`Error occurred while trying to delete attendee`);
            throw err;
          }
        });
      } else {
        props.rows.forEach((dataItem, index) => {
          if (dataItem.hasOwnProperty("Delete")) {
            dataItem.Delete(() => {
              if (index + 1 === props.rows.length) {
                if (props.hasOwnProperty("fetchData")) {
                  props.fetchData();
                }
              }
            });
          }
        });
      }
    }
  };

  return (
    <Fragment>
      <NxConfirm
        onClick={deleteItems}
        show={show}
        onClose={() => setShow(false)}
        text={`Are you sure you want to delete ${
          props.rows ? props.rows.length : 0
        } item/s?`}
      />
      <Grid className={"nx_grid_popover"} hidden={!props.show}>
        <Grid className={"nx_grid_popover_text"}>
          {props.rows?.length} items selected
        </Grid>
        <Grid
          style={{
            justifyContent: "center",
            alignItems: "center",
            gridAutoFlow: "column",
            backgroundColor: "#F9F8F8",
            boxShadow: "11px 12px 30px 0px rgba(0,0,0,0.75)",
          }}
        >
          {buttons && Object.keys(buttons).length
            ? Object.keys(buttons).map((button, index) => {
                return (
                  <Fragment key={index}>{buttons[button](props.rows)}</Fragment>
                );
              })
            : null}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Nx_Grid_Popover;
