import data_structure from "./data_structure.json";
import data_types from "./data_types";
import React from "react";
import { setValidations, validators } from "./data_validators";
import { Tooltip } from "@mui/material";

const data_configs = {};
const static_configs = {
  users: {
    ignoreValidation: ["id", "created_at", "updated_at"],
    fields: {
      id: {
        hide: true,
      },
      first_name: {
        field: "first_name",
        gridLabel: "Име и Презиме",
        label: "Име",
        validators: [validators.isNull, validators.isEmpty],
        renderCell: (row) => {
          const dataItem = row.row;
          return (
            <Tooltip title={`${dataItem.first_name} ${dataItem.last_name}`}>
              <div>
                {dataItem.first_name} {dataItem.last_name}
              </div>
            </Tooltip>
          );
        },
      },
      last_name: {
        field: "last_name",
        label: "Презиме",
        validators: [validators.isNull, validators.isEmpty],
      },
      email: {
        field: "email",
        label: "Емаил",
        validators: [
          validators.isNull,
          validators.isEmpty,
          validators.isNotEmail,
        ],
      },
      password: {
        field: "password",
        label: "Лозинка",
        validators: [
          validators.isNull,
          validators.isEmpty,
          validators.isNotValidPassword,
        ],
      },
      confirm_password: {
        field: "confirm_password",
        label: "Потврди лозинка",
        validators: [
          validators.isNull,
          validators.isEmpty,
          validators.isNotValidPassword,
          validators.isNotConfirmedPassword,
        ],
      },
      status: {
        field: "status",
        label: "Статус",
        datatype: data_types.reference,
        reference: "usersStatus",
        defaultValue: "active",
        filterable: true,
      },
      phone: {
        field: "phone",
        label: "Телефон",
        datatype: data_types.number,
      },
      user_type_id: {
        field: "user_type_id",
        label: "Тип на корисник",
        datatype: data_types.reference,
        reference: "user_types",
        filterable: true,
      },
      region_id: {
        field: "region_id",
        label: "Регион",
        datatype: data_types.reference,
        validators: [validators.isNull],
        reference: "regions",
        renderCell: (row) => {
          const value =
            typeof row.value === "string" ? JSON.parse(row.value) : row.value;

          if (Array.isArray(value)) {
            if (!value.length) {
              return <div>-</div>;
            }

            const valueSetted = value
              .map((region) => {
                return global.UF.dataProvider.referenceProvider.getReferenceLabel(
                  `regions`,
                  region
                );
              })
              .join(", ");
            return (
              <Tooltip title={valueSetted}>
                <div>{valueSetted}</div>
              </Tooltip>
            );
          }

          return (
            <div>
              {global.UF.dataProvider.referenceProvider.getReferenceLabel(
                `regions`,
                value
              )}
            </div>
          );
        },
      },
      specialist_type: {
        field: "specialist_type",
        label: "Тип на специјалист",
        datatype: data_types.reference,
        reference: "specialistTypes",
        validators: [validators.isNull, validators.isEmpty],
      },
      doctor_type: {
        field: "doctor_type",
        label: "Тип на доктор",
      },
    },
    errorHandling: (requestType, error, dataItem) => {
      console.log(error);
      const errorMsg = error.error;
      if (errorMsg.includes("users.email_UNIQUE")) {
        return {
          code: error.code,
          error: "Корисник со тој емаил веќе постои!",
        };
      }
      return error;
    },
  },
  regions: {
    ignoreValidation: ["id", "created_at", "updated_at"],
    fields: {
      id: {
        hide: true,
      },
      name: {
        field: "name",
        label: "Регион",
      },
      code: {
        field: "code",
        label: "Код на регионот",
      },
      description: {
        field: "description",
        label: "Регионална единица",
      },
      address: {
        field: "address",
        label: "Регионална адреса",
      },
      email: {
        field: "email",
        gridLabel: "Емаил",
        label: "Регионален емаил",
        validators: [
          validators.isNull,
          validators.isEmpty,
          validators.isNotEmail,
        ],
      },
      status: {
        field: "status",
        label: "Статус",
        defaultValue: "active",
        datatype: data_types.reference,
        reference: "regionsStatus",
      },
      phone: {
        field: "phone",
        gridLabel: "Телефон",
        phone: true,
        label: "Регионален телефон",
        datatype: data_types.number,
      },
    },
    errorHandling: (requestType, error, dataItem) => {
      const errorMsg = error.error;
      if (errorMsg.includes("regions.code_UNIQUE")) {
        return {
          code: error.code,
          error: "Веќе постои таков регион со тој код!",
        };
      }
      if (errorMsg.includes("ER_ROW_IS_REFERENCED_2")) {
        return {
          code: error.code,
          error: "Регионот не може да биде избришан,бидејќи има пациенти!",
        };
      }
      return error;
    },
  },
  municipalities: {
    ignoreValidation: [
      "id",
      "created_at",
      "updated_at",
      "created_by",
      "updated_by",
    ],
    fields: {
      id: {
        hide: true,
      },
      name: {
        field: "name",
        label: "Општина",
      },
      region_id: {
        field: "region_id",
        label: "Регион",
        datatype: data_types.reference,
        reference: "regions",
      },
    },
    errorHandling: (requestType, error, dataItem) => {
      const errorMsg = error.error;
      if (errorMsg.includes("regions.code_UNIQUE")) {
        return {
          code: error.code,
          error: "Веќе постои таков регион со тој код!",
        };
      }
      if (errorMsg.includes("ER_ROW_IS_REFERENCED_2")) {
        return {
          code: error.code,
          error: "Регионот не може да биде избришан,бидејќи има пациенти!",
        };
      }
      return error;
    },
  },
  patients: {
    ignoreValidation: ["id", "created_at", "updated_at", "ordinal_number"],
    fields: {
      id_number: {
        field: "id_number",
        label: "ЕМБГ",
        datatype: data_types.number,
        validators: [validators.isValidID],
        phone: true,
      },
      first_name: {
        field: "first_name",
        label: "Име",
      },
      fathers_name: {
        field: "fathers_name",
        label: "Татково име",
      },
      last_name: {
        field: "last_name",
        label: "Презиме",
      },
      date_of_birth: {
        field: "date_of_birth",
        label: "Датум на раѓање",
        datatype: data_types.date,
      },
      gender: {
        field: "gender",
        label: "Пол",
        datatype: data_types.reference,
        reference: "genderTypes",
      },
      address: {
        field: "address",
        label: "Адреса",
        validators: [validators.isNull, validators.isEmpty],
      },
      city: {
        field: "city",
        label: "Град",
        validators: [validators.isNull, validators.isEmpty],
      },
      community: {
        field: "community",
        label: "Општина",
        datatype: data_types.reference,
        reference: "municipalities",
        reference_groupby: "region_id",
        validators: [validators.isNull, validators.isEmpty],
      },
      living_place: {
        field: "living_place",
        label: "Населено место",
        validators: [validators.isNull, validators.isEmpty],
      },
      zip_code: {
        field: "zip_code",
        label: "Поштенски број",
        datatype: data_types.number,
        phone: true,
        validators: [validators.isNull, validators.isEmpty],
      },
      region_id: {
        field: "region_id",
        label: "Регион",
        datatype: data_types.reference,
        reference: "regions",
      },
      birth_place: {
        field: "birth_place",
        label: "Место на раѓање",
        validators: [validators.isNull, validators.isEmpty],
      },
      contact_phone: {
        field: "contact_phone",
        phone: true,
        label: "Контакт телефон",
        datatype: data_types.number,
        validators: [validators.isNull, validators.isEmpty],
      },
      contact_email: {
        field: "contact_email",
        label: "Електронска пошта",
        validators: [validators.isNotEmail],
      },
      contact_name: {
        field: "contact_name",
        label: "Контакт лице",
      },
      contact_relationship: {
        field: "contact_relationship",
        label: "Сродство со пациентот",
      },
    },
    errorHandling: (requestType, error, dataItem) => {
      const errorMsg = error.error;
      if (errorMsg.includes("patients.id_number_UNIQUE")) {
        return {
          code: error.code,
          error: "Пациент со тој ЕМБГ веќе постои!",
        };
      }
      return error;
    },
  },
  view_patients_book: {
    id: ["ordinal_number", "id_number", "subject_id"],
    // sorting: [{ field: "ordinal_number", sort: "asc" }],
    ignoreValidation: ["id", "created_at"],
    fields: {
      diagnosis_tag: {
        field: "diagnosis_tag",
        label: "Дијагноза",
        filterable: true,
        datatype: data_types.reference,
        reference: "view_code_setup_diagnoses_according_to_icd10",
        renderCell: (row, value) => {
          const diagnosis = row.row.formated_diagnosis_tag;
          if (!diagnosis.length) {
            return <div>-</div>;
          }

          let temp_arr = [];
          diagnosis.forEach((item) => {
            temp_arr.push(
              `${global.UF.dataProvider.referenceProvider.getReferenceLabel(
                "view_code_setup_diagnoses_according_to_icd10",
                item
              )} `
            );
          });

          const joined_string = temp_arr.join(", ");

          return (
            <Tooltip title={joined_string}>
              <div>{joined_string}</div>
            </Tooltip>
          );
        },
      },
      patient_id: {
        field: "patient_id",
        label: "",
      },
      region_id: {
        field: "region_id",
        label: "Регион",
        datatype: data_types.reference,
        reference: "regions",
        hide: true,
      },
      note: {
        field: "note",
        label: "Забелешки",
        datatype: data_types.string,
        multiline: true,
      },
      ordinal_number: {
        field: "ordinal_number",
        label: "Реден бр.",
      },
      fullname: {
        field: "fullname",
        label: "Име Татково Име",
      },
      responsible_person: {
        field: "responsible_person",
        label: "Одговорно лице",
      },
      date_of_birth: {
        field: "date_of_birth",
        label: "Датум на раѓање",
      },
      address: {
        field: "address",
        label: "Адреса на живеење/Град",
      },
      id_number: {
        field: "id_number",
        label: "ЕМБГ",
      },
      diagnosis: {
        field: "diagnosis",
        label: "Дијагноза",
        multiline: true,
      },
      date_of_assessment: {
        field: "date_of_assessment",
        label: "Датум на проценка",
      },
      date_of_reassessment: {
        field: "date_of_reassessment",
        label: "Датум на реевалуација",
      },
      date_of_publishing: {
        field: "date_of_publishing",
        label: "Датум на публикување",
      },
      contact_information: {
        field: "contact_information",
        label: "",
      },
      school_municipality: {
        field: "school_municipality",
      },
      recommendations: {
        field: "recommendations",
        label: "Препораки",
        multiline: true,
      },
      reevaluation_date: {
        field: "reevaluation_date",
      },
      submitted_to: {
        field: "submitted_to",
      },
      case_manager: {
        field: "case_manager",
      },
      number_of_revaluation: {
        field: "number_of_revaluation",
        label: "Број на реализирана реевалуација",
      },
      subject_type: {
        field: "subject_type",
        hide: true,
      },
      subject_id: {
        field: "subject_id",
        hide: true,
      },
      contact_name: {
        field: "contact_name",
        label: "Лице за контакт",
      },
      education: {
        field: "education",
        label: "Образование",
        multiline: true,
      },
    },
  },
  view_transfered_patients_book: {
    id: ["ordinal_number", "id_number", "subject_id"],
    // sorting: [{ field: "ordinal_number", sort: "asc" }],
    ignoreValidation: ["id", "created_at"],
    fields: {
      diagnosis_tag: {
        field: "diagnosis_tag",
        label: "Дијагноза",
        filterable: true,
        datatype: data_types.reference,
        reference: "view_code_setup_diagnoses_according_to_icd10",
        renderCell: (row, value) => {
          const diagnosis = row.row.formated_diagnosis_tag;
          if (!diagnosis.length) {
            return <div>-</div>;
          }

          let temp_arr = [];
          diagnosis.forEach((item) => {
            temp_arr.push(
              `${global.UF.dataProvider.referenceProvider.getReferenceLabel(
                "view_code_setup_diagnoses_according_to_icd10",
                item
              )} `
            );
          });

          const joined_string = temp_arr.join(", ");

          return (
            <Tooltip title={joined_string}>
              <div>{joined_string}</div>
            </Tooltip>
          );
        },
      },
      patient_id: {
        field: "patient_id",
        label: "",
      },
      region_id: {
        field: "region_id",
        label: "Регион",
        datatype: data_types.reference,
        reference: "regions",
        hide: true,
      },
      note: {
        field: "note",
        label: "Забелешки",
        datatype: data_types.string,
        multiline: true,
      },
      ordinal_number: {
        field: "ordinal_number",
        label: "Реден бр.",
      },
      fullname: {
        field: "fullname",
        label: "Име Татково Име",
      },
      responsible_person: {
        field: "responsible_person",
        label: "Одговорно лице",
      },
      date_of_birth: {
        field: "date_of_birth",
        label: "Датум на раѓање",
      },
      address: {
        field: "address",
        label: "Адреса на живеење/Град",
      },
      id_number: {
        field: "id_number",
        label: "ЕМБГ",
      },
      diagnosis: {
        field: "diagnosis",
        label: "Дијагноза",
        multiline: true,
      },
      date_of_assessment: {
        field: "date_of_assessment",
        label: "Датум на проценка",
      },
      date_of_reassessment: {
        field: "date_of_reassessment",
        label: "Датум на реевалуација",
      },
      date_of_publishing: {
        field: "date_of_publishing",
        label: "Датум на публикување",
      },
      contact_information: {
        field: "contact_information",
        label: "",
      },
      school_municipality: {
        field: "school_municipality",
      },
      recommendations: {
        field: "recommendations",
        label: "Препораки",
        multiline: true,
      },
      reevaluation_date: {
        field: "reevaluation_date",
      },
      submitted_to: {
        field: "submitted_to",
      },
      case_manager: {
        field: "case_manager",
      },
      number_of_revaluation: {
        field: "number_of_revaluation",
        label: "Број на реализирана реевалуација",
      },
      subject_type: {
        field: "subject_type",
        hide: true,
      },
      subject_id: {
        field: "subject_id",
        hide: true,
      },
      contact_name: {
        field: "contact_name",
        label: "Лице за контакт",
      },
      education: {
        field: "education",
        label: "Образование",
        multiline: true,
      },
    },
  },
  view_patients_reports: {
    id: ["ordinal_number"],
    ignoreValidation: ["id", "created_at"],
    fields: {
      patient_id: {
        field: "patient_id",
        label: "",
      },
      region_id: {
        field: "region_id",
        label: "Регион",
        datatype: data_types.reference,
        reference: "regions",
        hide: true,
      },
      note: {
        field: "note",
        label: "Забелешки",
        datatype: data_types.string,
        multiline: true,
      },
      ordinal_number: {
        field: "ordinal_number",
        label: "Реден бр.",
      },
      fullname: {
        field: "fullname",
        label: "Име Татково Име",
      },
      responsible_person: {
        field: "responsible_person",
        label: "Одговорно лице",
      },
      date_of_birth: {
        field: "date_of_birth",
        label: "Датум на раѓање",
      },
      address: {
        field: "address",
        label: "Адреса на живеење/Град",
      },
      id_number: {
        field: "id_number",
        label: "ЕМБГ",
      },
      diagnosis: {
        field: "diagnosis",
        label: "Дијагноза",
        multiline: true,
      },
      diagnosis_tag: {
        field: "diagnosis_tag",
        label: "Дијагноза",
        filterable: true,
        datatype: data_types.reference,
        reference: "view_code_setup_diagnoses_according_to_icd10",
        renderCell: (row, value) => {
          const diagnosis = row.value;
          if (!diagnosis.length) {
            return <div>-</div>;
          }

          let temp_arr = [];
          diagnosis.forEach((item) => {
            temp_arr.push(
              `${global.UF.dataProvider.referenceProvider.getReferenceLabel(
                "view_code_setup_diagnoses_according_to_icd10",
                item
              )} `
            );
          });

          const joined_string = temp_arr.join(", ");

          return (
            <Tooltip title={joined_string}>
              <div>{joined_string}</div>
            </Tooltip>
          );
        },
      },
      thoughts: {
        field: "thoughts",
        label: "Мислење",
        multiline: true,
      },
      thoughts_tag: {
        field: "thoughts_tag",
        label: "Мислење",
        filterable: true,
        datatype: data_types.reference,
        reference: "view_code_setup_disability",
        renderCell: (row) => {
          const thoughts = row.value;
          if (!thoughts.length) {
            return <div>-</div>;
          }

          let temp_arr = [];
          thoughts.forEach((item) => {
            temp_arr.push(
              `${global.UF.dataProvider.referenceProvider.getReferenceLabel(
                "view_code_setup_disability",
                item
              )} `
            );
          });

          const joined_string = temp_arr.join(", ");

          return (
            <Tooltip title={joined_string}>
              <div>{joined_string}</div>
            </Tooltip>
          );
        },
      },
      date_of_assessment: {
        field: "date_of_assessment",
        label: "Датум на проценка",
      },
      date_of_reassessment: {
        field: "date_of_reassessment",
        label: "Датум на реевалуација",
      },
      date_of_publishing: {
        field: "date_of_publishing",
        label: "Датум на публикување",
      },
      contact_information: {
        field: "contact_information",
        label: "",
      },
      school_municipality: {
        field: "school_municipality",
      },
      health_recommendation: {
        field: "health_recommendation",
        label: "Здравствена заштита",
        multiline: true,
      },
      health_recommendation_ref: {
        field: "health_recommendation_ref",
        label: "Здравствена заштита",
        datatype: data_types.reference,
        filterable: true,
        reference: "view_code_setup_health_care_measures",
        renderCell: (row) => {
          const parsed_value = row.value;
          if (!parsed_value.length) {
            return <div>-</div>;
          }
          let temp_arr = [];
          parsed_value.forEach((item) => {
            temp_arr.push(
              `${global.UF.dataProvider.referenceProvider.getReferenceLabel(
                "view_code_setup_health_care_measures",
                item
              )} `
            );
          });

          const joined_string = temp_arr.join(", ");
          return (
            <Tooltip title={joined_string}>
              <div>{joined_string}</div>
            </Tooltip>
          );
        },
      },
      learning_recommendation: {
        field: "learning_recommendation",
        label: "Образование",
        multiline: true,
      },
      learning_recommendation_ref: {
        field: "learning_recommendation_ref",
        label: "Образование",
        datatype: data_types.reference,
        filterable: true,
        reference: "view_code_setup_child_support_measures",
        renderCell: (row) => {
          const parsed_value = row.value;
          if (!parsed_value.length) {
            return <div>-</div>;
          }
          let temp_arr = [];
          parsed_value.forEach((item) => {
            temp_arr.push(
              `${global.UF.dataProvider.referenceProvider.getReferenceLabel(
                "view_code_setup_child_support_measures",
                item
              )} `
            );
          });

          const joined_string = temp_arr.join(", ");
          return (
            <Tooltip title={joined_string}>
              <div>{joined_string}</div>
            </Tooltip>
          );
        },
      },
      child_recommendation: {
        field: "child_recommendation",
        label: "Детска и социјална заштита",
        multiline: true,
      },
      child_recommendation_ref: {
        field: "child_recommendation_ref",
        label: "Детска и социјална заштита",
        datatype: data_types.reference,
        filterable: true,
        reference: "view_code_setup_social_protection_measures",
        renderCell: (row) => {
          const parsed_value = row.value;
          if (!parsed_value.length) {
            return <div>-</div>;
          }
          let temp_arr = [];
          parsed_value.forEach((item) => {
            temp_arr.push(
              `${global.UF.dataProvider.referenceProvider.getReferenceLabel(
                "view_code_setup_social_protection_measures",
                item
              )} `
            );
          });

          const joined_string = temp_arr.join(", ");
          return (
            <Tooltip title={joined_string}>
              <div>{joined_string}</div>
            </Tooltip>
          );
        },
      },
      other_recommendation: {
        field: "other_recommendation",
        label: "Други",
        multiline: true,
      },
      reevaluation_date: {
        field: "reevaluation_date",
      },
      submitted_to: {
        field: "submitted_to",
      },
      case_manager: {
        field: "case_manager",
      },
      number_of_revaluation: {
        field: "number_of_revaluation",
        label: "Број на реализирана реевалуација",
      },
      subject_type: {
        field: "subject_type",
        hide: true,
        label: "Тип на предмет",
        datatype: data_types.reference,
        reference: "subjectTypes",
        filterable: true,
      },
      subject_id: {
        field: "subject_id",
        hide: true,
      },
      contact_name: {
        field: "contact_name",
        label: "Лице за контакт",
      },
      education: {
        field: "education",
        label: "Образование",
        multiline: true,
      },
    },
  },
  code_setup: {
    ignoreValidation: ["id", "created_at"],
    fields: {
      category: {
        field: "category",
        label: "Категорија",
        datatype: data_types.reference,
        reference: "codeSetupCategories",
      },
      code: {
        field: "code",
        label: "Код",
      },
      description: {
        field: "description",
        label: "Опис ",
      },
      type_of_protection: {
        field: "type_of_protection",
        label: "Тип на заштита",
        datatype: data_types.reference,
        reference: "codeSetupTypesOfProtection",
      },
    },
  },
  transfer_history: {
    ignoreValidation: ["created_at", "created_by", "id"],
    fields: {
      patient_fullname: {
        field: "patient_fullname",
        label: "Пациент",
      },
      old_region_id: {
        field: "old_region_id",
        label: "Стар регион",
        datatype: data_types.reference,
        reference: "regions",
      },
      new_region_id: {
        field: "new_region_id",
        label: "Нов регион",
        datatype: data_types.reference,
        reference: "regions",
      },
      transfer_reason: {
        field: "transfer_reason",
        label: "Причина",
        flex: 2,
      },
      created_at: {
        field: "created_at",
        label: "Датум на креирање",
      },
    },
  },
  subjects: {
    sorting: [{ field: "created_at", sort: "asc" }],
    fields: {
      subject_type: {
        filterable: true,
        field: "subject_type",
        label: "Тип на предмет",
        datatype: data_types.reference,
        reference: "subjectTypes",
      },
      status: {
        filterable: true,
        field: "status",
        label: "Статус на предмет",
        datatype: data_types.reference,
        reference: "subjectStatuses",
      },
      created_by: {
        field: "created_by",
        label: "Креиран од",
        datatype: data_types.reference,
        reference: "users",
      },
      created_at: {
        field: "created_at",
        label: "Датум на креирање",
      },
      delovoden_broj: {
        field: "delovoden_broj",
        label: "Деловоден број",
      },
      patient_id: {
        field: "patient_id",
        label: "Пациент",
        reference: "patients",
        datatype: data_types.autocomplete,
      },
    },
  },
  view_all_subjects_with_region_id: {
    sorting: [{ field: "created_at", sort: "asc" }],
    fields: {
      subject_type: {
        filterable: true,
        field: "subject_type",
        label: "Тип на предмет",
        datatype: data_types.reference,
        reference: "subjectTypes",
      },
      status: {
        field: "status",
        label: "Статус на предмет",
        datatype: data_types.reference,
        reference: "subjectStatuses",
      },
      created_by: {
        field: "created_by",
        label: "Креиран од",
        datatype: data_types.reference,
        reference: "users",
      },
      created_at: {
        field: "created_at",
        label: "Датум на креирање",
      },
      delovoden_broj: {
        field: "delovoden_broj",
        label: "Деловоден број",
      },
    },
  },
  view_schedule_ups: {
    id: ["subject_id"],
    fields: {
      date_of_reassessment: {
        field: "date_of_reassessment",
        label: "Датум",
        renderCell: (row) => {
          const { value } = row;
          return (
            <Tooltip title={value}>
              <div style={new Date() > new Date(value) ? { color: "red" } : {}}>
                {value}
              </div>
            </Tooltip>
          );
        },
      },
      number: {
        field: "number",
        label: "Повторна проценка на",
      },
      embg: {
        field: "embg",
        label: "ЕМБГ",
      },
      first_name: {
        field: "first_name",
        label: "Име",
      },
      last_name: {
        field: "last_name",
        label: "Презиме",
      },
      region_id: {
        field: "region_id",
        label: "Регион",
        datatype: data_types.reference,
        reference: "regions",
      },
    },
  },
};

const parseDataType = (name, type) => {
  if (type.indexOf("char") > -1) {
    return data_types.string;
  }

  if (type.indexOf("enum") > -1) {
    return data_types.string;
  }

  switch (type) {
    case "timestamp":
      return data_types.time;
    case "date":
      return data_types.date;
    case "datetime":
      return data_types.datetime;
    case "time":
      return data_types.time;
    case "int":
    case "double":
      return data_types.number;
    case "tinyint(1)":
    case "bit(1)":
      return data_types.boolean;
    case "json":
      return data_types.json;
    case "text":
    case "longtext":
      return data_types.string;
  }

  if (
    type.indexOf("int") > -1 ||
    type.indexOf("float") > -1 ||
    type.indexOf("long") > -1 ||
    type.indexOf("decimal") > -1
  ) {
    return data_types.number;
  }

  console.error(`New datatype for field : ${name} that is type : ${type}`);
  return data_types.string;
};

const capitalizeFirstLetter = (string) => {
  if (string.length) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};

const generateFieldsName = (label) => {
  if (label.includes("_")) {
    return `${capitalizeFirstLetter(
      label.split("_")[0]
    )} ${capitalizeFirstLetter(label.split("_")[1])}`;
  } else {
    return capitalizeFirstLetter(label);
  }
};

const handleCustomRenderCell = (field) => {
  if (!field.hasOwnProperty("renderCell")) {
    if (field.Field.toLowerCase().includes("email")) {
      field.renderCell = (params) => {
        return (
          <a style={{ textDecoration: "none" }} href={`mailto:${params.value}`}>
            {params.value}
          </a>
        );
      };
    }
    if (field.Field.toLowerCase().includes("phone")) {
      field.renderCell = (params) => {
        return (
          <a style={{ textDecoration: "none" }} href={`tel:${params.value}`}>
            {params.value}
          </a>
        );
      };
      field.phone = true;
    }
  }
};

Object.keys(data_structure).forEach((table) => {
  data_configs[table] = {};
  let fields = data_structure[table].fields;
  let curConfig = data_configs[table];
  curConfig.fields = {};
  curConfig.fieldsArr = [];
  if (static_configs[table]) {
    curConfig.ignoreValidation = static_configs[table].ignoreValidation;
    curConfig.sorting = static_configs[table].sorting;
    curConfig.filters = static_configs[table].filters;
    curConfig.additionalData = static_configs[table].additionalData;
    curConfig.errorHandling = static_configs[table].errorHandling;
    curConfig.id = static_configs[table].id;
  }

  fields.forEach((field) => {
    let curField = {
      ...field,
      field: field.Field,
      name: field.Field,
      label: generateFieldsName(field.Field),
      nullable: field.Null != "NO",
      datatype: parseDataType(field.Field, field.Type),
    };

    handleCustomRenderCell(curField);

    if (static_configs[table] && static_configs[table].fields[field["Field"]]) {
      curField = Object.assign(
        curField,
        static_configs[table].fields[field["Field"]]
      );
    }

    if (curField.field.includes("password")) {
      curField.datatype = data_types.password;
    }

    if (curField.datatype === data_types.number) {
      if (!curField.hasOwnProperty("min")) {
        curField.min = 0;
      }
    }

    if (
      curField.hasOwnProperty("defaultValue") == false &&
      curField.datatype == data_types.boolean
    ) {
      curField.defaultValue = false;
    }
    if (
      curField.hasOwnProperty("defaultValue") == false &&
      (curField.datatype == data_types.date ||
        curField.datatype == data_types.datetime)
    ) {
      let date = new Date();
      curField.timezone = "UTC";
      curField.defaultValue = `${date.getUTCFullYear()}-${
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1
      }-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`;
    }
    if (curField.datatype == "time") {
      curField.timezone = "UTC";
    }
    if (curField.field == "updated_at") {
      curField.defaultValue = null;
    }
    if (
      curField.hasOwnProperty("defaultValue") == false &&
      curField.datatype == data_types.color
    ) {
      curField.defaultValue = "#000";
    }
    curConfig.fields[field["Field"]] = curField;
    curConfig.fieldsArr.push(curField);
  });
  setValidations(data_configs[table]);
});

export default data_configs;
