import React, { useState, useEffect, useRef } from "react";
import MixedTags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";
import NxDialog from "../../../../nix_components/ui_components/dialogs/nx_dialog";
import { Typography } from "@mui/material";
import Nx_Ctrl_TextInput from "../../../../nix_components/ui_components/controls/nx_ctrl_textinput";
import Grid from "../../../../nix_components/ui_components/layouts/Grid/Grid";

const SubjectTag = ({ reference, subject_details, field, read_only }) => {
  const ref = useRef(null);
  const actualRef = useRef(null);
  const [items, setItems] = useState([]);
  const [usedReference, setUsedReference] = useState(() => {
    let temp_reference = "";
    const split = reference.split(".");
    temp_reference = split[1];
    if (temp_reference.includes(":")) {
      temp_reference = temp_reference.split(":")[0];
    }
    return temp_reference;
  });
  const [el, setEl] = useState(null);
  const [diagnosisCode, setDiagnosisCode] = useState("");
  const [diagnosisDescription, setDiagnosisDescription] = useState("");
  const [error, setErrors] = useState({
    diagnosisCodeError: "",
    diagnosisDescriptionError: "",
  });
  const [showModal, setShowModal] = useState(false);

  const settings = {
    pattern: /@|#/,
    mode: "mix",
    dropdown: {
      enabled: 0,
      maxItems: Infinity,
    },
    editTags: true,
    mixMode: {},

    enforceWhitelist:
      usedReference === "view_code_setup_diagnoses_according_to_icd10"
        ? false
        : true,
  };

  const modalButtons = [
    {
      label: "Откажи",
      variant: "text",
      onClick: () => handleCancel(),
    },
    {
      label: "Додај",
      variant: "text",
      onClick: () => handleAddingRecord(),
    },
  ];

  const fetchReference = () => {
    global.UF.dataProvider.referenceProvider.get(
      usedReference,
      (data) => {
        const tempItems = data.map((item) => {
          return { id: item.id, value: item.label };
        });
        setItems(tempItems);
      },
      false
    );
  };

  useEffect(() => {
    fetchReference();
  }, [reference]);

  const onChange = (e) => {
    const value = e.target.value;
    subject_details[field] = value;
  };

  const handleOnAdd = (e) => {
    if (usedReference == "view_code_setup_diagnoses_according_to_icd10") {
      const value = e.detail.data;
      if (!value.hasOwnProperty("id")) {
        setShowModal(true);
        setDiagnosisCode(value.value);
      }
    }
  };

  const handleCancel = (e) => {
    const value = subject_details[field];
    const regexPattern = /\[\[{"value":"[^"]+","prefix":"@"}\]\](?=\s*\[\[|$)/g;
    const matches = value.match(regexPattern);
    subject_details[field] = subject_details[field].replace(
      matches[matches.length - 1],
      " "
    );
    setEndOfContenteditable();
    setShowModal(false);
    setDiagnosisCode("");
    setDiagnosisDescription("");
  };

  const handleAddingRecord = () => {
    const dataItem = global.UF.dataProvider.datastructure["code_setup"].new();
    dataItem.code = diagnosisCode;
    dataItem.description = diagnosisDescription;
    dataItem.category = "diagnoses_according_to_icd10";

    dataItem.Save((res) => {
      const { id } = res;
      const value = subject_details[field];
      const regexPattern =
        /\[\[{"value":"[^"]+","prefix":"@"}\]\](?=\s*\[\[|$)/g;
      const matches = value.match(regexPattern);
      subject_details[field] = value.replace(
        matches[matches.length - 1],
        `[[{"id" : "${id}", "value" : "${diagnosisCode} - ${diagnosisDescription}", "prefix" : "@"}]]`
      );
      fetchReference();
      setShowModal(false);
      setDiagnosisCode("");
      setDiagnosisDescription("");
      setEndOfContenteditable();
    });
  };

  function setEndOfContenteditable(contentEditableElement = el) {
    var range, selection;
    if (document.createRange) {
      range = document.createRange();
      range.selectNodeContents(contentEditableElement);
      range.collapse(false);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    } else if (document.selection) {
      //IE 8 and lower
      range = document.body.createTextRange();
      range.moveToElementText(contentEditableElement);
      range.collapse(false);
    }
  }

  const renderAddNewPopUp = () => {
    return (
      <NxDialog
        open={showModal}
        handleClose={handleCancel}
        buttons={modalButtons}
      >
        <Typography variant="h6">
          Дали сте сигурни дека сакате да додадете нова дијагноза? <br />
        </Typography>

        <Grid
          style={{
            gap: "20px",
            gridTemplateColumns: "repeat(2, 1fr)",
            marginTop: "40px",
          }}
        >
          <Nx_Ctrl_TextInput
            onChange={(value) => setDiagnosisCode(value)}
            label={`Код`}
            type={"text"}
            error={error.diagnosisCodeError.length > 0}
            helperText={error.diagnosisCodeError}
            value={diagnosisCode}
          />
          <Nx_Ctrl_TextInput
            onChange={(value) => setDiagnosisDescription(value)}
            label={`Опис`}
            type={"text"}
            error={error.diagnosisDescriptionError.length > 0}
            helperText={error.diagnosisDescriptionError}
            value={diagnosisDescription}
          />
        </Grid>
      </NxDialog>
    );
  };

  //don't touch this part , it's react.tagify bug
  setTimeout(() => {
    if (subject_details[field]) {
      ref.current.loadOriginalValues(subject_details[field]);
    }
  }, 100);

  const divFocused = (e) => {
    setEndOfContenteditable(e.target);
    setEl(e.target);
  };

  return (
    <>
      {showModal && renderAddNewPopUp()}
      <div onFocus={divFocused}>
        <MixedTags
          tagifyRef={ref}
          settings={settings}
          whitelist={items}
          onAdd={handleOnAdd}
          // autoFocus={true}
          className={"subject_tag"}
          onChange={onChange}
          value={subject_details[field]}
          readOnly={read_only}
        />
      </div>
    </>
  );
};

export default SubjectTag;
