import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";

const Nx_Ctrl_Autocomplete = ({
  dataItem,
  fieldConfig,
  onChange,
  data,
  value,
  disabled,
  className,
  error,
  helperText,
  read_only,
}) => {
  const [items, setItems] = useState([]);
  const [displayedItems, setDisplayedItems] = useState([]); // For lazy loading
  const [loading, setLoading] = useState(false); // Loading state for fetching items
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const itemsPerPage = 20; // How many items to load at a time

  // Fetch and sort the items
  useEffect(() => {
    if (fieldConfig.reference) {
      setLoading(true);
      global.UF.dataProvider.referenceProvider.get(
        fieldConfig.reference,
        (retrievedItems) => {
          retrievedItems = retrievedItems.sort((a, b) =>
            a.label.localeCompare(b.label)
          );
          setItems(retrievedItems);
          setDisplayedItems(retrievedItems.slice(0, itemsPerPage)); // Initial items for lazy loading
          setLoading(false);
        }
      );
    }
  }, [fieldConfig?.reference]);

  // Handle updates to `data` prop
  useEffect(() => {
    if (data !== null) {
      const loadedItems = Array.isArray(data) ? data : Object.values(data);
      setItems(loadedItems);
      setDisplayedItems(loadedItems.slice(0, itemsPerPage));
    }
  }, [data]);

  // Handle input change (search query)
  const handleSearchChange = (event, newInputValue) => {
    setSearchQuery(newInputValue); // Update search query
    const filteredItems = items.filter((item) =>
      item.label.toLowerCase().includes(newInputValue.toLowerCase())
    );
    setDisplayedItems(filteredItems.slice(0, itemsPerPage)); // Reset displayed items based on search
  };

  // Handle value change
  const handleValueChange = (event, newValue) => {
    if (dataItem) {
      dataItem[fieldConfig.name] = newValue?.id || null; // Set the selected value in the dataItem
    }
    if (onChange) {
      onChange(newValue?.id || null); // Trigger the onChange callback with the selected value
    }
  };

  // Function to load more items when the user scrolls
  const loadMoreItems = () => {
    if (loading) return;
    setLoading(true);
    setTimeout(() => {
      const nextItems = items.slice(
        displayedItems.length,
        displayedItems.length + itemsPerPage
      );
      setDisplayedItems((prev) => [...prev, ...nextItems]);
      setLoading(false);
    }, 300); // Simulated delay to mimic loading new items
  };

  // Attach the scroll event to the dropdown
  const handleScroll = (event) => {
    if (
      event.target.scrollHeight - event.target.scrollTop ===
      event.target.clientHeight
    ) {
      loadMoreItems(); // Load more items when the user scrolls to the bottom
    }
  };

  return (
    <>
      <FormControl
        sx={
          fieldConfig && fieldConfig.width
            ? { width: fieldConfig.width }
            : { width: "100%" }
        }
        className={`nx_textfield${
          read_only ? "_read_only" : ""
        } nx_selectfield ${error ? "nx_error" : ""} ${
          className ? className : ""
        }`}
      >
        <Autocomplete
          disabled={disabled || fieldConfig.disabled}
          size={fieldConfig?.size || "small"}
          options={displayedItems} // Display only a subset of items for lazy loading
          loading={loading}
          getOptionLabel={(option) => option.label || ""}
          value={items.find((item) => item.id === value) || null} // Ensure the selected value is visible
          onInputChange={handleSearchChange} // Handle search input
          onChange={handleValueChange} // Handle selection
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          ListboxProps={{
            role: "list-box",
            onScroll: handleScroll,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={fieldConfig?.label || fieldConfig?.name}
              error={error}
              helperText={helperText}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </FormControl>
    </>
  );
};

Nx_Ctrl_Autocomplete.propTypes = {
  dataItem: PropTypes.object,
  fieldConfig: PropTypes.object,
  onChange: PropTypes.func,
  data: PropTypes.array,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  read_only: PropTypes.bool,
};

export default Nx_Ctrl_Autocomplete;
