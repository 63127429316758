import React from "react";
import "../Login/log_in.scss";
import withRouter from "../../../nix_components/components/withRouter.js";
import Nx_Ctrl_TextInput from "../../../nix_components/ui_components/controls/nx_ctrl_textinput.js";
import { validators } from "../../../nix_components/data/data_validators.js";
import Nx_Button from "../../../nix_components/ui_components/controls/nx_button.js";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      repeat_password: "",
      is_error: false,
      error_message: "",
      user: null,
    };
    this.handleFetchUserByToken();
  }

  handleFetchUserByToken = async () => {
    const { navigate, location } = this.props.router;
    if (!location.search.length) {
      global.UF.setAlertVisibility(
        true,
        global.UF.tr("forgot_password_token_doesnt_match"),
        "info"
      );
      navigate("/");
    }

    const uuid = location.search.split("=")[1];
    try {
      const users = await global.UF.dataProvider.get_v2(`users`, {
        forgot_password_token: uuid,
      });
      if (!users.length) {
        global.UF.setAlertVisibility(
          true,
          global.UF.tr("forgot_password_token_doesnt_match"),
          "info"
        );
        navigate("/");
      }

      const user = users[0];
      this.setState({ user });
    } catch (err) {
      console.error(err);
      global.UF.setAlertVisibility(true, err.toString(), "error");
      return;
    }
  };

  validatePassword = (password) => {
    const validatorsArr = [
      validators.isNull,
      validators.isEmpty,
      validators.isNotValidPassword,
    ];

    for (const validator of validatorsArr) {
      if (validator.func(password)) {
        this.setState({
          is_error: true,
          error_message: validator.msg,
        });
        return false;
      }
    }
    return true;
  };

  handleValidation = () => {
    const valid =
      this.validatePassword(this.state.password) &&
      this.validatePassword(this.state.repeat_password);

    if (!valid) {
      return false;
    }

    if (this.state.password !== this.state.repeat_password) {
      this.setState({ error_message: global.UF.tr("passwords_doesnt_match") });
      return false;
    }

    return true;
  };

  handleResetPassword = async () => {
    if (!this.handleValidation()) {
      return;
    }

    const { user } = this.state;

    if (!user) {
      return;
    }

    const response = await global.UF.makeRequest_v2(
      `POST`,
      `/api/set-password`,
      { id: user.id, password: this.state.password }
    );

    console.log(response);

    this.props.router.navigate("/");
  };

  onKeyDown = async (e) => {
    if (e.keyCode === 13) {
      await this.handleResetPassword();
    }
  };

  render() {
    return (
      <div className="uf_login">
        <div className="uf_login_card">
          <div className="uf_login_logo_section">
            <div className="uf_login_logo">
              <img src={`./assets/images/defektolozi.png`} alt={"logo"} />
            </div>
          </div>
          <div className="uf_login_login">
            <div className="uf_login_text">Промени лозинка</div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
                flexDirection: "column",
              }}
            >
              <div className="uf_login_textfield_wrapper">
                <Nx_Ctrl_TextInput
                  onChange={(password) => this.setState({ password })}
                  label={global.UF.tr("password")}
                  type={"password"}
                  error={this.state.error_message.length > 0}
                  helperText={this.state.error_message}
                  value={this.state.password}
                  onKeyDown={this.onKeyDown}
                  inputStyle={{ height: "25px", borderRadius: "10px" }}
                />
              </div>
              <div className="uf_login_textfield_wrapper">
                <Nx_Ctrl_TextInput
                  onChange={(repeat_password) =>
                    this.setState({ repeat_password })
                  }
                  label={"Потврди лозинка"}
                  type={"password"}
                  error={this.state.error_message.length > 0}
                  helperText={this.state.error_message}
                  value={this.state.repeat_password}
                  onKeyDown={this.onKeyDown}
                  inputStyle={{ height: "25px", borderRadius: "10px" }}
                />
              </div>
            </div>

            <div className={"uf_login_button_container"}>
              <Nx_Button
                label={`Промени лозинка`}
                onClick={async () => await this.handleResetPassword()}
                variant={"contained"}
              />
              <Nx_Button
                label={global.UF.tr("log_in")}
                onClick={() => this.props.router.navigate("/")}
                variant={"text"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ResetPassword);
