import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "MK",
    lg: "MK",
    languages: ["MK"],
    resources: {
      MK: {
        translation: {
          welcome_to_page: "Добредојдовте на страната на Здравствен Дом",
          email_address: "Емаил адреса",
          password: "Лозинка",
          log_in: "Најави се",
          forgot_password: "Заборавена лозинка",
          user_with_that_email_doesnt_exist:
            "Корисник са овим емаилом не постоји",
          send_email: "Пошаљи емаил",
          reset_password: "Промени лозинка",
          confirm_password: "Потврди лозинка",
          forgot_password_token_doesnt_match:
            "Токен за ресетовање лозинке није валидан",
          passwords_doesnt_match: "Лозинке се не поклапају",
          choose_language: "Изабери језик",
          super_admin: "Главен Администратор",
          regional_admin: "Регионален Администратор",
          social_worker: "Социјален работник",
          specialist: "Специјалист",
          doctor: "Доктор",
          male: "Машко",
          female: "Женско",
          active: "Активен",
          not_active: "Неактивен",
          psychologist: "Психолог",
          special_educator_and_rehabilitator:
            "Специјален едукатор и рехабилитатор",
          all_regions: "Сите региони",
          child_support_measures: "Мерки за поддршка на дете",
          social_protection_measures: "Мерки за социјална заштита",
          health_care_measures: "Мерки за здравствена заштита",
          diagnoses_according_to_icd10: "Дијагнози по МКБ-10",
          codesbody_functions_according_to_ICF: "Кодови-телесни функции по МКФ",
          participation_codes: "Кодови-учество",
          disability: "Попреченост",
          icf_qualifier: "МКФ Квалификатор",
          indicator: "Индикатор",
          codes_facilitator_and_barrier: "Олеснувач и Бариера",
          social_protection: "СОЦИЈАЛНА ЗАШТИТА",
          child_protenction: "ДЕТСКА ЗАШТИТА",
        },
      },
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
