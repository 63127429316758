import React, { PureComponent, Fragment } from "react";
import Grid from "../../../nix_components/ui_components/layouts/Grid/Grid";
import "./profile.scss";
import {
  UI_Form,
  UI_Form_Row,
  UF_Form_Cell,
} from "../../../nix_components/components/form_wrappers/controls/nx_form_controls";
import Nx_Ctrl_Generic from "../../../nix_components/ui_components/controls/nx_ctrl_generic";
import Nx_Button from "../../../nix_components/ui_components/controls/nx_button";
import SetPasswordModal from "./components/SetPasswordModal";

export default class MyProfile extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showValidations: false,
      passwordItem: null,
      user: props.user,
    };
    this.config = {
      rows: [
        ["first_name", "last_name"],
        ["email", "phone"],
      ],
    };
    this.dataConfigs = global.UF.dataProvider.datastructure["users"];
    this.dataConfigs.ignoreValidation.push(
      "password",
      "confirm_password",
      "specialist_type",
      "region_id"
    );
  }

  renderRow = (row, index) => {
    if (row && row.length) {
      return (
        <Fragment key={index}>
          <UI_Form_Row>
            {row.map((field, index) =>
              this.renderField(field, row.length, index)
            )}
          </UI_Form_Row>
        </Fragment>
      );
    }
    return null;
  };

  renderField = (field, col, index) => {
    let tempField = field.hasOwnProperty("length")
      ? this.dataConfigs.fields[field]
      : { ...this.dataConfigs.fields[field.field], ...field };
    return (
      <Fragment key={index}>
        <UF_Form_Cell cols={col}>
          <Nx_Ctrl_Generic
            dataItem={this.state.user}
            showValidation={this.state.showValidations}
            fieldConfig={tempField}
            onKeyDown={tempField.multiline ? () => {} : this.onKeyDown}
          />
        </UF_Form_Cell>
      </Fragment>
    );
  };

  onSave = async () => {
    const changedFields = this.state.user.GetChangedFields();
    if (!Object.keys(changedFields).length) {
      global.UF.setAlertVisibility(true, "Нема никакви промени!", "warning");
      return;
    }

    const isValid = await this.state.user.Validate();

    if (!isValid) {
      this.setState({ showValidations: true });
      return;
    }

    this.state.user.Save(() => {
      this.setState({ showValidations: false });
    });
  };

  onKeyDown = async (event) => {
    if (event.keyCode == 13) {
      await this.onSave();
    }
  };

  setPassword = async (password, old_password, user) => {
    try {
      const response = await global.UF.makeRequest_v2(
        `POST`,
        `/api/myprofile/set-password`,
        { id: user.id, password: password, old_password: old_password },
        true
      );
      this.setState({ passwordItem: null });
    } catch (err) {
      console.error(err);
    }
  };

  renderSetPasswordModal = () => {
    return (
      <SetPasswordModal
        open={Boolean(this.state.passwordItem)}
        onClose={() => this.setState({ passwordItem: null })}
        onSetPassword={async (password, old_password) =>
          await this.setPassword(
            password,
            old_password,
            this.state.passwordItem
          )
        }
      />
    );
  };

  handleSetPasswordClick = () => {
    this.setState({ passwordItem: this.state.user });
  };

  render() {
    return (
      <Grid className="profile_card">
        {this.state.passwordItem && this.renderSetPasswordModal()}
        <Grid className="profile_title">Мој профил</Grid>
        <Grid className="profile_content">
          <img
            src="./assets/images/profile.png"
            alt="profile"
            className="profile_image"
          />
          <Grid
            style={{ justifyItems: "center", gridTemplateRows: "80% auto" }}
          >
            <UI_Form>
              {this.config.rows.map((row, index) => this.renderRow(row, index))}
            </UI_Form>
            <Grid
              style={{
                gridAutoFlow: "column",
                width: "35%",
                justifySelf: "end",
              }}
            >
              <Nx_Button
                variant={"text"}
                label={"Промени лозинка"}
                onClick={this.handleSetPasswordClick}
              />
              <Nx_Button
                variant={"text"}
                label={"Сочувај"}
                onClick={async () => await this.onSave()}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
